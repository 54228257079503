.ethical__section-three--container {
  background: #f9f7fd;
  padding: 8rem 0rem;
}

.section__three-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0rem 4rem;
}

.ethical__section-three--heading {
  font-size: 22px;
  font-weight: 700;
  line-height: 29.11px;
  text-align: center;
  margin-bottom: 5rem;
}

.ethical__section-three--boxes {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  margin-bottom: 6rem;
}

.ethical__section-three--box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ethical__section-three--box-img {
  width: 15rem;
  margin-bottom: 3rem;
  box-shadow: 0px 8px 30px 0px #e3e9ef80;
  border-radius: 50%;
}

.ethical__section-three-box--heading {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 21.17px;
  margin-bottom: 1.2rem;
  text-align: center;
}

.ethical__section-three-box--sub-text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 22.4px;
  color: #6b6790;
  text-align: center;
  max-width: 40rem;
}

.ethical__section-3__enroll-CTA {
  width: 20rem;
  height: 6rem;
  border-radius: 4rem;
  border: 1px 0px 0px 0px;
  font-family: Red Hat Display;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 31.23px;
  color: #181a49;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
}

@media screen and (min-width: 62.5rem) {
  .ethical__section-three--heading {
    font-size: 36px;
    font-weight: 700;
    line-height: 47.63px;
    margin-bottom: 6rem;
  }

  .ethical__section-three--boxes {
    gap: 6rem;
    flex-direction: row;
  }

  .ethical__section-three--box-img {
    width: 21rem;
    margin-bottom: 5rem;
    box-shadow: 0px 8px 30px 0px #e3e9ef80;
  }

  .ethical__section-three-box--heading {
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 34.4px;
    margin-bottom: 2rem;
  }
  .ethical__section-three-box--sub-text {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
  }

  .ethical__section-3__enroll-CTA {
    width: 23rem;
    height: 7rem;
    border: 1.5px 0px 0px 0px;
    font-size: 2rem;
    font-weight: 800;
    line-height: 26.46px;
  }
}

@media screen and (min-width: 90rem) {
  .ethical__section-three--boxes {
    gap: 12rem;
    padding: 0 12rem;
  }
}
