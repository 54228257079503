.question__container {
  width: 100%;
  max-height: 50rem;
  min-height: 41rem;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
  display: flex;
  padding: 0 5rem;
  padding: 3rem 3.1rem;
  margin-bottom: 3rem;
  overflow-y: scroll;
  flex-direction: column;
  position: relative;
}

.close__quiz-icon {
  display: none;
}

.with__image {
  width: 853px;
  height: 770px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  padding: 0 5rem;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
  left: 0px;
  top: 0px;
}

.question__number-and-close__icon {
  /* background-color: aqua; */
  display: flex;
  justify-content: space-between;
  /* height: 8.6rem; */
  padding: 0rem 0rem 1.5rem 0rem;
  align-items: center;
  border-bottom: 1px solid #f2f5f9;
  margin-bottom: 2.4rem;
}

.question__number {
  color: #181a49;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.question__img--span {
  display: flex;
  justify-content: center;
}

.question__img {
  width: 34.7rem;
  height: 19.4rem;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 3rem;
}

.question__main--text-and-options {
  /* background-color: #d885a3; */
  /* height: 30rem; */
  overflow-y: scroll;
  /* margin-bottom: 4rem; */
}

.question__main--text {
  color: #181a49;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 152.5%;
  margin-bottom: 3.5rem;
}

.question__answers--prompt {
  color: #807d9e;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 3.5rem;
}

.answer__option {
  font-size: clamp(1.2rem, 1.4vw, 1.4rem);
  color: #181a49;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.question__nav--CTAs {
  /* background-color: #655d8a; */
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.answer__option-and-check {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.answer__option {
  margin-bottom: 5rem;
}

/* .pretty .state label:before {
    border-color: red !important;
  } */
.previous__question--CTA {
  /* width: 107px;
  height: 50px; */
  padding: 0.9rem 5.2rem;
  background: #fff;
  border: 1px solid rgba(154, 204, 251, 0.72);
  border-radius: 4rem;
  color: #181a49;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.next__question--CTA {
  padding: 0.9rem 5.2rem;
  border-radius: 4rem;
  background: var(
    --linner-3,
    linear-gradient(243deg, #eae2ff 0%, rgba(187, 221, 252, 0.28) 100%)
  );
  color: #181a49;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.finish__CTA {
  padding: 0.9rem 5.2rem;
  border-radius: 4rem;
  background: var(
    --linner-3,
    linear-gradient(243deg, #eae2ff 0%, rgba(187, 221, 252, 0.28) 100%)
  );
  color: #181a49;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* The container */
.container {
  /* display: block; */
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 12px;  */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  left: 3px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background: #fff;
  border: 1px solid rgba(24, 26, 73, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  background: #fff;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  width: 14px;
  height: 14px;
  background: rgba(24, 26, 73, 1);
  border-radius: 50%;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid rgba(223, 223, 223, 1);
}

/* Style the indicator (dot/circle) */
.container:hover .checkmark:after {
  border-radius: 50%;
  background: #fff;
  border: 1px solid #9accfb;
}

@media screen and (max-width: 78rem) {
  .with__image {
    height: auto;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 48rem) {
  .question__container {
    max-height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    position: fixed;
    margin-bottom: 0;
    padding: 13rem 2rem 5rem 2rem;
    z-index: 10;
    background: #f8fafc;
  }

  .question__content {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
    padding: 2rem;
  }

  .close__quiz-icon {
    display: block;
  }

  .question__img {
    width: 300px;
    height: 173.55px;
  }
  .with__image {
    padding: 3rem 2.8rem 10rem 2.8rem;
    border-radius: 0;
  }

  .question__main--text-and-options {
    /* background-color: #d885a3; */
    overflow-y: visible;
    margin-bottom: 0rem;
  }

  .answer__option {
    margin-bottom: 3.5rem;
  }

  .previous__question--CTA {
    padding: 0.7rem 2.2rem;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .next__question--CTA {
    padding: 0.7rem 2.2rem;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .finish__CTA {
    /* width: 97px;
    height: 48px; */
    padding: 0.7rem 2.2rem;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #07532a;
  }
}
