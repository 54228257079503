.join__slack-community--container {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cykea__logo-span--slack-com {
  margin-top: 3rem;
}

.verified__token-span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 2rem;
  text-align: center;
  width: 100%;
  margin-top: 15rem;
}

.Join__slack-community--text {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #171531;
  margin: 5rem 0;
}

.join__slack-community--CTA {
  width: 30%;
  height: 56px;

  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  font-weight: 700;
  font-size: 1.5rem;
  border-radius: 75px;
  color: #181a49 !important;

}

@media screen and (max-width: 48rem) {
  .join__slack-community--CTA {
    width: 53%;
  }
}
