/* @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Righteous&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap");

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 400;
  line-height: 1.6;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input {
  margin: var(--input-gutter);
  border-radius: var(--border-radius2);
  /* font-size: 15.9px; */
}

button {
  font-family: "Red Hat Display", sans-serif;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:disabled,
button[disabled] {
  border-radius: 75px;
  background: var(
    --linerr-deeper,
    linear-gradient(243deg, #e6dcff 0%, rgba(154, 204, 251, 0.72) 100%)
  );
  color: #181a49;
  opacity: 50%;
}

.loader__container {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1000;
  background-color: #00000078;
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode),
.video-js.vjs-16-9:not(.vjs-audio-only-mode),
.video-js.vjs-4-3:not(.vjs-audio-only-mode),
.video-js.vjs-9-16:not(.vjs-audio-only-mode),
.video-js.vjs-1-1:not(.vjs-audio-only-mode) {
  border-radius: 1.5rem;
}

.video-js .vjs-tech {
  border-radius: 1.5rem;
}

.vjs-theme-forest {
  --vjs-theme-forest--primary: #181a49;
}

.vjs-theme-forest .vjs-big-play-button {
  background: url(./assets/icons/play-video-icon.svg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

/* .vjs-text-track-display {
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  border-radius: 1rem;
} */

.vjs-text-track-display {
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  border-radius: 1rem;
  transition: 0.3s ease-in-out;
  background-image: linear-gradient(to top, black, rgba(78, 78, 78, 0.249));
  height: 100% !important;
  display: block !important;
}
.vjs-text-track-no-display {
  /* height: 3rem; */
  pointer-events: none;
}

.vjs-theme-forest .vjs-control-bar {
  background: rgba(174, 174, 174, 0.14);
  z-index: 10 !important;
}

.vjs-theme-forest .vjs-control-bar {
  background: #020202a1 !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.vjs-menu-item-text {
  color: #fff;
}

.vjs-menu li.vjs-selected {
  opacity: 1;
  transition: 0.3s ease-in-out;
  background-image: linear-gradient(to bottom, black, rgba(78, 78, 78, 0.249));
}

._access-icon {
  display: none !important;
  font-family: "Red Hat Display", sans-serif !important;
}

._access-menu ul li button {
  color: #181a49 !important;
}

._access-menu ._text-center {
  color: #181a49 !important;
}

._access-menu ._menu-reset-btn {
  color: #181a49 !important;
}

@media screen and (max-width: 48rem) {
  input {
    font-size: 1.3rem;
  }
}
