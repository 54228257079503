.reset__pWord-container {
  height: 100vh;
  background-color: #ffffff;
  width: 100%;
  padding: 5.7rem 9.5rem;
}

.reset__pWord-form--container {
  height: 96%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reset__pWord-form {
  width: 527px;
  /* height: 414px; */
  background: #ffffff;
  border: 0.7px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgba(212, 212, 212, 0.1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 4rem;
}

.reset__password-heading--text {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #171531;
  margin-bottom: 4rem;
  text-align: center;
}

.reset__pWord-sub--text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #858585;
  margin-bottom: 2.9rem;
}

.reset__pword-input--label {
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #757575;
}

.reset__pWord-new--pWord {
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 4rem;
  margin-top: 1rem;
  margin-bottom: 3.5rem;
  padding-left: 2rem;
}

.reset__pWord-CTA {
  width: 100%;
  height: 56px;
  background: linear-gradient(
    266.17deg,
    #eae2ff 35.33%,
    rgba(187, 221, 252, 0.28) 121.98%
  );
  border-radius: 4rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #181a49;
}
.password__reset-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 22px rgba(212, 212, 212, 0.1);
  border-radius: 15px;
  width: 527px;
  padding: 4rem;
}

.reset__success-text {
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 24px;
  color: #171531;
  margin-bottom: 6rem;
}

.password__reset-icon {
  height: 12.2rem;
  width: 13.3rem;
  margin-bottom: 4.5rem;
}

.login__after-reset--CTA {
  background: linear-gradient(
    266.17deg,
    #eae2ff 35.33%,
    rgba(187, 221, 252, 0.28) 121.98%
  );
  border-radius: 75px;
  width: 100%;
  height: 57px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #181a49;
}

@media screen and (max-width: 100rem) {
  .reset__success-text {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #171531;
  }
}

@media screen and (max-width: 48rem) {
  .reset__pWord-container {
    padding: 3rem 5rem;
  }

  .reset__pWord-form {
    width: 100%;
  }

  .password__reset-success {
    width: 100%;
  }

  .reset__pword-desk-logo {
    opacity: 0;
  }
}

@media screen and (max-width: 28rem) {
  .reset__pWord-container {
    padding: 3rem 2rem;
  }
}
