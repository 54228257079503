.ethical__enroll-form--container {
  background: #f8f5ff;
  padding: 9rem 2rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enroll__form-content {
  display: flex;
  justify-content: center;
}

.enroll__form-container {
  padding: 3rem 2.3rem;
  background-color: #ffffff;
  border: 0.75px solid rgba(187, 221, 252, 0.28);
  box-shadow: 0px 8px 20.8px 0px #b3adfa1c;
  border-radius: 3rem;
  max-width: 60rem;
}

.enroll__form-logo--span {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.enroll__form-notice {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 18.52px;
  text-align: left;
  margin-bottom: 1rem;
  color: #655d8a;
}

.enroll__form-notice--text {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
  text-align: left;
  margin-bottom: 3rem;
  color: #6b6790;
}

.ethical__enroll-form {
}

.enroll__input-label {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 17.2px;
  text-align: left;
  color: #6b6790;
}

.enroll__form-input {
  height: 4.8rem;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 15.88px;
  text-align: left;
  padding-left: 1.5rem;
  margin-top: 1.2rem;
  margin-bottom: 2.5rem;
  border-radius: 5.4rem;
  border: 1.5px solid #d9d8ff;
  font-family: Red Hat Display;
}

.enroll__form-disclaimer--text {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin-bottom: 2rem;
  color: #6b6790;
}

.enroll__form-CTAs {
}

.enroll__Pay-CTA {
  font-family: Red Hat Display;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 18.52px;
  width: 100%;
  height: 4.8rem;
  border-radius: 4rem;
  border: 0.7px 0px 0px 0px;
  color: #181a49;
  background-color: #ffffff;
  border: 0.7px solid rgba(154, 204, 251, 0.72);
}

.pay__now-CTA {
  margin-bottom: 1.5rem;
}

.pay__later-CTA {
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  border: none;
}

.ethical__form__dropdown__container {
  position: relative;
}

.ethical__location-input--container {
  position: relative;
  height: fit-content;
}

.ethical__form-dropdown {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  padding: 1.5rem;
  background: #ffffff;
  border: 0.75px solid #e5e5e5;
  border-radius: 1.5rem;
  box-shadow: 0px 8px 30px 0px #e3e9ef80;
  width: 20.2rem;
  position: absolute;
  top: 7rem;
  right: 0;
}

.down__down-item {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  padding-bottom: 1.3rem;
  cursor: pointer;
}

.down__icon {
  position: absolute;
  cursor: pointer;
  top: 3.5rem;
  right: 5%;
}

.down__down-item:not(:last-child) {
  border-bottom: 0.75px solid #e5e5e5;
}

.dropdown-icon {
}

.dropdown-location {
  font-family: Red Hat Display;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 21.17px;
  text-align: left;
  color: #6b6790;
}

@media screen and (min-width: 62.5rem) {
  .enroll__form-container {
    padding: 3.5rem 4rem;
  }

  .enroll__form-notice {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 21.17px;
  }

  .enroll__form-notice--text {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 19.85px;
  }

  .enroll__input-label {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 18.52px;
    margin-bottom: 1.5rem;
  }

  .enroll__form-input {
    height: 5rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 15.88px;
    padding-left: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  }

  .enroll__form-disclaimer--text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 21.17px;
    margin-bottom: 3rem;
    max-width: 50rem;
  }
}
