.user__details--form-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0px 5.702127933502197px 21.382978439331055px 0px
    rgba(227, 233, 239, 0.5);
  padding: 4rem 2.5rem;
}

.user__details--password-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0px 5.702127933502197px 21.382978439331055px 0px
    rgba(227, 233, 239, 0.5);
  padding: 4rem 2.5rem;
}

.userDetails--label {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  color: #949699;
  margin-bottom: 1rem;
}

.profile__eye__icon {
  position: absolute;
  height: 1.5rem;
  top: 1.5rem;
  left: 89.5%;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.user__details--input {
  display: flex;
  flex-direction: column;
}

.save__user--data-CTA {
  width: 100%;
  box-sizing: border-box;
  padding: 1.7rem 0rem;
  border-radius: 7.5rem;
  background: var(
    --linerr-deeper,
    linear-gradient(243deg, #e6dcff 0%, rgba(154, 204, 251, 0.72) 100%)
  );
  color: #181a49;
  font-family: Red Hat Display;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.user__data {
  width: 429.23px;
  height: 48px;
  background: #f8f8f8;
  border: 0.8px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5.4rem;
  padding: 1rem 2rem;
  color: #858585;
  font-family: Red Hat Display;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 3rem;
}

@media screen and (max-width: 40rem) {
  .user__details--form-container {
    width: 100%;
    padding: 4rem 2rem;
  }

  .user__details--password-container {
    width: 100%;
    padding: 4rem 2rem;
  }

  .user__details--input {
    width: 100%;
  }

  .user__data {
    width: 100%;
  }

  .save__user--data-CTA {
    width: 100%;
    padding: 1.6rem 0rem;
    font-size: 1.2rem;
  }
}
