.series__container {
  width: 321px;
  height: 200px;
  background: linear-gradient(
    102.07deg,
    #db8dff 41.19%,
    rgba(255, 247, 56, 0.55) 103.51%
  );
  box-shadow: 0px 2px 22px rgba(253, 253, 253, 0.1);
  border-radius: 10px;
  background-position: center;
  /* filter: drop-shadow(0px 4px 40px rgba(239, 239, 239, 0.2)); */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1.7rem 3.4rem 1.7rem 0;
  padding: 2.2rem;
  cursor: pointer;
  position: relative;
}

.pending__purchase-overlay {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 2px 22px rgba(253, 253, 253, 0.1);
  border-radius: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
}

.pending__purchase-text {
  font-family: "Sora";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 169.5%;
  text-align: center;
  color: #ffffff;
}

.course__title {
  font-family: "Sora";
  font-style: normal;
  width: 19rem;
  font-weight: 400;
  font-size: clamp(1.8rem, 2.3vw, 2.5rem);
  line-height: 156.5%;
  color: #ffffff;
}
/* .greyedOut {
  background-color: #655d8a;
  width: 321px;
  height: 200px;
  background: #4da0ed;
  box-shadow: 0px 2px 22px rgba(253, 253, 253, 0.1);
  border-radius: 1.5rem;
  background-position: center;
  filter: grayscale(100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 1.7rem 3.4rem 1.7rem 0;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.75;
} */

/* .series__container:hover {
  box-shadow: 0px 2px 22px rgba(253, 253, 253, 0.1);
} */

.series__details {
  opacity: 0;
  height: 100%;
  width: 100%;
}

/* .series__details:hover {
  background-color: aqua;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem 2.5rem;
  background-color: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  background: #ffffff;
  border: 1px solid #f2f5f9;
  box-shadow: 0px 4px 40px rgba(239, 239, 239, 0.2);
  border-radius: 15px;
} */

.title-and-points {
  display: flex;
  justify-content: center;
  align-items: center;
}

.series__title--text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.series__points {
  /* font-family: "Sora"; */
  font-style: normal;
  font-weight: 600;
  font-size: clamp(1.2rem, 1.8vw, 1.8rem);
  line-height: 156.9%;
  color: #d885a3;
  display: flex;
}

.videos__number {
  /* font-family: "Sora"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-left: 2rem;
  color: #949699;
}
.scenarios-and-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.videoCOunt__and__icon {
  display: flex;
  align-items: center;
}

.videoDuration__and__icon {
  display: flex;
  align-items: center;
}

.scenarios__number {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #949699;
  margin-left: 2rem;
}

.progressPercentage__and__bar {
  display: flex;
  align-items: center;
}

.percentage__completed {
  /* font-family: "Sora"; */
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.5rem, 2vw, 2rem);
  line-height: 156.9%;
  color: #171531;
  margin-right: 2rem;
}

@media screen and (max-width: 26rem) {
  .series__container {
    background-color: #655d8a;
    width: 89%;
    height: 19.5rem;
    background-position: center;
    filter: drop-shadow(0px 4px 40px rgba(239, 239, 239, 0.2));

    margin: 1.7rem 0rem 1.7rem 0;
  }

  .series__details {
    width: 90%;
  }
}
