.course__detail-banner {
  background: #0f0f0fb3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course__detail-container {
  background-color: #fff;
  border-radius: 1.5rem;
}

.free__text-box {
  background-color: #181a49;
  padding: 3rem 0;
  border-radius: 1.5rem 1.5rem 0 0;
}

.free__text {
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 152.5%;
  text-align: center;
  background: linear-gradient(
    266deg,
    #e6dcff 41.33%,
    rgba(154, 204, 251, 0.72) 72%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.free__course-benefits {
  list-style: none;
  padding: 3.5rem 2rem 2.7rem 3rem;
  width: 396px;
}

.free__course-benefit {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.free__benefit-icon {
  margin-right: 1.2rem;
}

.benefit__text {
  color: #181a49;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.enroll__for-free--CTA {
  color: #181a49;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4rem;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  padding: 1.2rem;
  width: 100%;
}

@media screen and (max-width: 29rem) {
  .course__detail-banner {
    padding: 0 2rem;
  }

  .course__detail-container {
    width: 100%;
  }

  .free__course-benefits {
    width: 100%;
  }
}
