.waitlist__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
  padding: 3rem 9rem 10rem 9rem;
  /* background-color: aquamarine; */
}

.hb__form-container {
  height: 100vh;
  width: 100%;
  padding: 5rem;
}

.waitList__container--content {
  height: fit-content;
}

.logo-and-join--CTA {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6.3rem;
}

.waitlist__text-and-image--container {
  display: flex;
  /* background-color: gold; */
  /* height: 55rem; */
  width: 100%;
  margin-bottom: 15rem;
}

.waiitlist__txt-container {
  /* background-color: rebeccapurple; */
  /* width: 84%; */
  flex: 1;
}

.waitList__main--text {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(11rem, 14vw, 14rem);
  line-height: 153.5%;
  color: #171531;
}

.waitList__sub--text {
  font-style: normal;
  font-weight: 400;
  font-size: 5rem;
  line-height: 135%;
  color: #949699;
}

.join__CTA {
  width: 136px;
  height: 49px;
  background: #a734dd;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
}

.join__waitlList-CTA {
  width: 287px;
  height: 75px;
  background: #a734dd;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
}

.join__CTA-and-poweredBy {
  display: flex;
  justify-content: space-between;
}

.poweredBy__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 10rem; */
  justify-content: center;

  /* background-color: red; */
}
.partners__logos-span {
  display: flex;
}

.join__waitlList-CTA {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video__content--container {
  width: 50%;
  height: fit-content;
}

.video__container-and-background {
  /* width: 70%; */
  flex: 1;
  /* max-height: 561.84px; */
  /* background-image: url(../../assets/images/video-background.png); */
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  position: relative;
}

.another {
  /* background-image: url(../../assets/images/video-background.png); */
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.video__player--div {
  /* animation: smooth-zigzag-1 7s linear infinite; */
  position: absolute;
  /* width: 651x; */
  min-height: 90%;
  margin-top: 4rem;
  margin-left: 4rem;
  top: 52px;
  left: 0;
  /* bottom: 0; */
  right: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes smooth-zigzag-1 {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(10px, 10px);
  }
  50% {
    transform: translate(5px, 5px);
  }
  75% {
    transform: translate(20px, -5px);
  }
}

.video__player {
  width: 95%;
  /* width: 651px; */
  /* height: 100%; */
  /* min-height: fit-content; */
  object-fit: cover;
}
.guarding__div {
  /* height: 100%;
  width: 100%; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: gold; */
}

@media screen and (max-width: 125rem) {
  .video__player--div {
    /* animation: smooth-zigzag-1 7s linear infinite; */
    position: absolute;
    min-height: 61%;
    margin-top: 4rem;
    margin-left: 4rem;
    top: 19px;
    left: 0;
    /* bottom: 0; */
    right: 0;
  }
  .waitlist__text-and-image--container {
    display: flex;
    /* background-color: gold; */
    height: 55rem;
    width: 100%;
    margin-bottom: 0;
  }

  .join__CTA-and-poweredBy {
    display: flex;
    justify-content: space-between;
    /* margin-top: 68rem; */
  }

  .waitList__main--text {
    font-family: "Sora", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: clamp(3rem, 7vw, 7rem);
    line-height: 153.5%;
    color: #171531;
  }

  .waitList__sub--text {
    width: 91%;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(1.2rem, 2.3vw, 2.3rem);
    line-height: 135%;
    color: #949699;
  }

  .join__waitlList-CTA {
    width: 287px;
    height: 75px;
    background: #a734dd;
    border: 1px solid #e5e5e5;
    border-radius: 15px;

    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    color: #ffffff;
  }

  .join__CTA {
    width: 136px;
    height: 49px;
    background: #a734dd;
    border: 0.5px solid #e5e5e5;
    border-radius: 15px;
  }
}

@media screen and (max-width: 90rem) {
  .video__player--div {
    /* animation: smooth-zigzag-1 7s linear infinite; */
    position: absolute;
    height: 59%;
    margin-top: 4rem;
    margin-left: 4rem;
    top: 4px;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
@media screen and (max-width: 78rem) {
  .waitlist__container {
    padding: 3rem 2rem 10rem 2rem;
  }
  .suitcase__icon {
    width: 58px;
    height: 45px;
  }

  .waitList__sub--text {
    /* width: 490px; */
  }
  /* .video__player--div {
    animation: smooth-zigzag-1 5s linear infinite;
    position: absolute;
    width: 651x;
    height: 425px;
    top: 42px;
    left: 40px;
    bottom: 0;
    right: 0;
    margin-top: 0rem;
    margin-left: 0rem;
  } */

  /* .waitList__main--text {
    font-style: normal;
    font-weight: 600;
    font-size: clamp(2.3rem, 4vw, 4rem);
    line-height: 153.5%;
    color: #171531;
  } */

  /* .waitList__sub--text {
    font-style: normal;
    font-weight: 400;
    font-size: clamp(1.7rem, 2.3vw, 2.3rem);
    line-height: 135%;
    color: #949699;
  } */

  .another {
    /* background-image: url(../../assets/images/video-background.png); */
    width: 100%;
    height: auto;
    background-position: center;
    background-size: cover;
  }

  /* .video__player {
    width: 93%;
    height: 325px;
  } */

  .waitlist__text-and-image--container {
    height: fit-content;
  }
}

@media screen and (max-width: 76rem) {
  /* .video__player {
    width: 93%;
    height: 325px;
  } */
}
@media screen and (max-width: 66.7rem) {
  /* .video__player {
    width: 93%;
    height: 65%;
  } */
}
@media screen and (max-width: 64rem) {
  .video__player--div {
    /* animation: smooth-zigzag-1 7s linear infinite; */
    position: absolute;
    height: 59%;
    margin-top: 4rem;
    margin-left: 4rem;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .waitlist__text-and-image--container {
    display: flex;

    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 62rem) {
  /* .video__player {
    width: 95%;
    height: 58%;
  } */
  /* .waitList__main--text {
    font-style: normal;
    font-weight: 600;
    font-size: clamp(2.3rem, 4vw, 4rem);
    line-height: 153.5%;
    color: #171531;
  } */
}
@media screen and (max-width: 57.3rem) {
  .video__container-and-background {
    width: 89%;
    max-height: 561.84px;
    /* background-image: url(../../assets/images/video-background.png); */
    background-position: center;
    background-size: cover;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
    position: relative;
  }
  /* .video__player {
    width: 94%;
    height: 103%;
  } */
  /* .waitList__main--text {
    font-style: normal;
    font-weight: 600;
    font-size: clamp(2.3rem, 4vw, 4rem);
    line-height: 153.5%;
    color: #171531;
  } */

  .join__CTA-and-poweredBy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .waitlist__text-and-image--container {
    display: block;
  }

  .join__waitlList-CTA {
    width: 135px;
    height: 45px;
    background: #a734dd;
    border: 0.5px solid #e5e5e5;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
  }

  .join__arrow {
    display: none;
  }
  .poweredBy__container {
    align-items: flex-start;
  }
}

@media screen and (max-width: 48rem) {
  .video__player--div {
    /* height: 77%; */
  }
}
@media screen and (max-width: 51.4rem) {
  /* .video__player {
    height: 75%;
  } */

  .waitlist__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100vh; */
    padding: 3.7rem 2rem 10rem 2rem;
  }

  /* .waitList__sub--text {
    width: 490px;
    height: 107px;
  } */
}
/* @media screen and (max-width: 40rem) {
  .video__player {
    height: 64%;
  }
}
@media screen and (max-width: 31rem) {
  .video__player {
    height: 64%;
  }
} */
@media screen and (max-width: 26.6rem) {
  .video__player--div {
    /* animation: smooth-zigzag-1 7s linear infinite; */
    position: absolute;
    /* height: 71%; */
    margin-top: 4rem;
    margin-left: 4rem;
    top: -5px;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
@media screen and (max-width: 26rem) {
  /* .video__player {
    height: 43%;
  } */

  .waitList__sub--text {
    margin-bottom: 2rem;
  }

  .waitlist__container {
    height: fit-content;
    padding: 3.7rem 2rem 20rem 2rem;
  }

  .another {
    margin-bottom: 3.5rem;
  }

  .join__waitlList-CTA {
    margin-bottom: 4.8rem;
  }

  .suitcase__icon {
    width: 27.47px;
    height: 25px;
  }

  .video__player--div {
    /* animation: smooth-zigzag-1 7s linear infinite; */
    height: 71%;
    margin-top: 3rem;
    margin-left: 4rem;
    top: -5px;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .video__player {
    width: 96%;
    height: 93%;
  }
  .logo-and-join--CTA {
    margin-bottom: 4.4rem;
  }
}
@media screen and (max-width: 24rem) {
  /* .video__player {
    height: 38%;
    width: 96%;
  } */

  .waitlist__container {
    padding: 5rem 2rem;
  }

  .video__player--div {
    /* height: 425px; */
    top: -18px;
    left: -25px;
  }
  .video__player {
    width: 96%;
    height: 91%;
  }

  .suitcase__icon {
    width: 27.47px;
    height: 25px;
  }

  .waitList__main--text {
    margin-bottom: 3.5rem;
  }

  .waitList__sub--text {
    margin-bottom: 3.5rem;
  }

  .join__CTA {
    display: none;
  }
}
