.practical__questions-container {
  margin-bottom: 2.6rem;
  /* background-color: red; */
  overflow-y: scroll;
}

.notification__position-div {
  position: relative;
}

.practical__question-heading {
  color: #181a49;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 2.5rem;
}

.continue__CTA-span {
  display: flex;
  justify-content: flex-end;
}

.continue__practQuestion-CTA {
  padding: 0.9rem 3.8rem;
  border-radius: 40px;
  background: var(
    --linner-3,
    linear-gradient(243deg, #eae2ff 0%, rgba(187, 221, 252, 0.28) 100%)
  );
  color: #181a49;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.practical__question-heading-and-close--CTA {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  border-bottom: 1px solid #eae2ff;
  margin-bottom: 3rem;
}

@media screen and (max-width: 48rem) {
  .practical__questions-container {
    margin-bottom: 2.6rem;
    background-color: #fff;
    overflow-y: scroll;
    padding: 1.5rem;
    border-radius: 2rem;
  }

  .practical__question-heading-and-close--CTA {
    margin-bottom: 2.5rem;
  }
}
