.share__cert-main--page {
  padding: 4rem;
}

.shared__cert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
  box-shadow: 0;
}

.shared__cert--content-container {
  width: 0;
}

@media screen and (max-width: 44rem) {
  .shared__cert--content-container {
    width: 100%;
  }
}
