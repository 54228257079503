.ethical__hacking-pricing--container {
  background: #f9f7fd;
  padding: 9rem 0rem;
}

.ethical__hacking-pricing--heading {
  text-align: center;
  margin-bottom: 2rem;
}

.ethical__hacking-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ethical__hacking-pricing--box {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 34rem;
  border: 1px solid rgba(187, 221, 252, 0.28);
  border-radius: 1.5rem;
  margin-bottom: 3rem;
}

.ethical__hacking-price--text-span {
  background-color: #181a49;
  width: 100%;
  padding: 2.1rem;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
}

.price__figure {
  font-family: Red Hat Display;
  font-size: 3rem;
  font-weight: 700;
  line-height: 45.75px;
  text-align: center;
  background: linear-gradient(
    266.74deg,
    #c4aeff 18.99%,
    #c2adfa 43.05%,
    #7eb9ff 72.11%,
    #7eb9ff 103.42%
  );
  color: transparent;
  background-clip: text;
}

.ethical__hacking-price--benefits {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2.5rem;
}

.ethical__hacking-benefit--span {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

.ethical__hacking-price--benefit {
  font-family: Red Hat Display;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 21.35px;
}

.ethical__hacking-CTA--span {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.limited__slot-text {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.52px;
  text-align: center;
  color: #6b6790;
}

.pricing__enrollment-CTA {
  background-color: #181a49;
  width: 20rem;
  height: 6rem;
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  line-height: 31.23px;
  border-radius: 4.2rem;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 62.5rem) {
  .ethical__hacking-pricing--heading {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 47.63px;
    margin-bottom: 4rem;
  }

  .ethical__hacking-pricing--box {
    width: 81.4rem;
    margin-bottom: 4rem;
  }

  .price__figure {
    font-size: 6rem;
    font-weight: 700;
    line-height: 91.5px;
  }

  .ethical__hacking-price--benefit {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 31.75px;
  }

  .ethical__hacking-price--benefits {
    padding: 4rem 0rem 4rem 4rem;
  }

  .ethical__hacking-CTA--span {
    flex-direction: row;
    gap: 3rem;
    align-items: center;
  }

  .limited__slot-text {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 23.81px;
    width: 11.4rem;
    text-align: left;
  }

  .pricing__enrollment-CTA {
    margin-bottom: 0;
    height: 7rem;
    width: 25.9rem;
    font-weight: 800;
    font-size: 2rem;
  }
}
