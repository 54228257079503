.sponsors__container {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 9rem 2rem;
  position: relative;
  /* width: 100%; */
}

.stats__container-heading {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 156.5%;
  color: #171531;
  margin-bottom: 15rem;
}

.stats__container {
  display: flex;
  /* background-color: #949699; */
  width: 100%;
  justify-content: center;
  margin-bottom: 11rem;
}

.stat__box {
  width: 380px;
  height: auto;
  /* background: red; */
  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(222, 206, 228, 0.2);
  border-radius: 20px;
  position: relative;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  padding: 0 2rem 4rem 2rem;
}

.number__stat-container {
  width: 18rem;
  height: 18rem;
  background: linear-gradient(
    149.16deg,
    #f6efff 3.45%,
    rgba(183, 109, 230, 0.68) 56.09%,
    rgba(153, 47, 219, 0.66) 81.31%
  );
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0px 14px 40px rgba(235, 180, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  left: 50%;
  top: -25rem; */
  margin-top: -10rem;
  margin-bottom: 3rem;
}

.number__stat {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 173%;
  color: #ffffff;
}

.stat__full-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 173%;
  text-align: center;
  color: #7d7e95;
}

.stat__box:not(:last-child) {
  margin-right: 5rem;
}

.powered__by--text {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #949699;
  margin-bottom: 4.2rem;
}

.sponsors__logos-and-text-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.sponsors__logos-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 550.35px;
}

.mobile__logo {
  display: none;
}

.web__logo {
  display: block;
}

@media screen and (max-width: 64rem) {
  .number__stat-container {
    width: 15rem;
    height: 15rem;
    margin-top: -7rem;
  }

  .number__stat {
    font-size: 2rem;
  }

  .stat__full-text {
    font-size: 1.5rem;
  }

  .stat__box:not(:last-child) {
    margin-right: 3rem;
  }
}

@media screen and (max-width: 48rem) {
  .stats__container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0rem;
  }

  .number__stat-container {
    width: 12rem;
    height: 12rem;
  }

  .number__stat {
    font-size: 1.7rem;
  }

  .stat__full-text {
    font-size: 1.4rem;
  }

  .stat__box {
    margin: 0 0 11rem 0;
    height: 20.6rem;
  }

  .stat__box:not(:last-child) {
    margin-right: 0rem;
  }
}
@media screen and (max-width: 38rem) {
  .powered__by--text {
    font-family: "Sora";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #949699;
  }

  .sponsors__container {
    padding: 4rem 2rem;
  }

  .sponsors__logos-and-text-span {
    width: 100%;
  }

  .sponsors__logos-span {
    width: 46%;
    /* background-color: #949699; */
  }

  .mobile__logo {
    display: block;
  }

  .web__logo {
    display: none;
  }
}

@media screen and (max-width: 26.4rem) {
  .stats__container-heading {
    font-size: 3rem;
    margin-bottom: 13rem;
  }

  .stat__box {
    width: 100%;
  }
}
