.faqAccordion__container {
  width: 100%;
  /* background-color: aquamarine; */
}

.faq__accordion-question--span {
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.3rem;
  /* border: 1px solid #ffeaf2; */
  padding: 2rem;
  border-radius: 1rem;
}

.faqs__accordion-open--icon {
  height: 60px;
  min-width: 60px;
}

.faqs__accordion-closed--icon {
  height: 60px;
  min-width: 60px;
}
.faq__question-and-list--dot {
  display: flex;
  align-items: center;
}

.list__dot {
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background: #fffba0;
  margin-right: 1.5rem;
}

.faq__question-text {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 53px;
  display: flex;
  align-items: center;
  color: #171531;
  margin-right: 2rem;
}

.faqs__question-answer--container {
  width: 806px;
  height: auto;
  border-radius: 15px;
  font-weight: 400;
  font-size: 28px;
  line-height: 145.3%;
  line-height: 157%;
  color: #181a49;
  margin-left: 1.5rem;
  padding: 1.5rem;
  margin-bottom: 3.5rem;
}

.bold__question {
  font-weight: 700 !important;
}

@media screen and (max-width: 100rem) {
  .faqAccordion__container {
    width: 100%;
    /* background-color: aquamarine; */
  }

  .faq__accordion-question--span {
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.3rem;
  }

  .faq__question-and-list--dot {
    display: flex;
    align-items: center;
  }

  .list__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fffba0;
    margin-right: 1.5rem;
  }

  .faq__question-text {
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 53px;
    display: flex;
    align-items: center;
    color: #171531;
  }

  .faqs__question-answer--container {
    width: 806px;
    height: auto;
    border-radius: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 157%;
    color: #181a49;
    margin-left: 1.5rem;
    padding: 1.5rem;
    margin-bottom: 3.5rem;
  }
}

@media screen and (max-width: 70rem) {
  .faqs__question-answer--container {
    width: 95%;
  }
}

@media screen and (max-width: 48rem) {
  .faq__question-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }

  .faq__accordion-question--span {
    padding: 2rem 0;
  }

  .faqs__accordion-open--icon {
    height: 25px;
    width: 25px;
  }

  .faqs__accordion-closed--icon {
    height: 25px;
    width: 25px;
  }

  .list__dot {
    width: 5px;
    height: 5px;
  }

  .faq__question-text {
    margin: 0;
  }

  .faqs__question-answer--text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #181a49;
  }
}
