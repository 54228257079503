.login__container {
  height: 100vh;
  background-color: #ffffff;
  display: flex;
}

.login__cykea--logo {
  height: 16rem;
  width: 13rem;
}
.navLogo-and-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  height: 8%;
}

.login__form--container {
  width: 50%;
  background: #ffffff;
  padding: 6rem 15rem 4.5rem 16rem;
}

.form-and-text__container {
  height: fit-content;
  display: flex;

  flex-direction: column;
  justify-content: center;
}

.login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.mail__icon {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  right: 0;
  bottom: 0;
}

.login__email-and-icon {
  position: relative;
}

.login__password-and-icons {
  position: relative;
}

.lock__icon {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  right: 0;
  bottom: 0;
}

.login__eye-icon {
  position: absolute;
  height: 3rem;
  top: 2.5rem;
  left: 92%;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.login__form--texts {
  display: flex;
  flex-direction: column;
}

.login__formheading {
  font-style: normal;
  font-weight: 700;
  font-size: 6rem;
  text-align: center;
  line-height: 10rem;
  color: #181a49 !important;
  margin-bottom: 3rem;
}

.login__form-sub--text {
  font-weight: 400;
  font-size: 30rem;
  line-height: 15px;
  color: #858585;
}

.login__email--label {
  font-weight: 600;
  font-size: 3rem;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  color: #6b6790;
}
.login__password--label {
  font-weight: 600;
  font-size: 3rem;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  color: #6b6790;
}

.login__email--input {
  width: 100%;
  height: 8rem;
  padding-left: 6rem;
  box-sizing: border-box;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 4rem;
  font-size: 2.5rem;
}
.email--input {
  height: 48px;
  padding-left: 3rem;
  background: #ffffff;
  border: 0.8px solid #e5e5e5;
  border-radius: 4rem;
  box-sizing: border-box;
}

.forgot__passwordone {
  color: #655d8a;
  margin-top: 3rem;
  font-size: 2.3rem;
  font-weight: 700;
  cursor: pointer;
  width: fit-content;
}

.email--input:focus {
  outline: none !important;
  border: 1px solid #f95959;
}
.login__email--input:focus {
  outline: none !important;
  border: 1px solid #d885a3;
}

.login__password--input {
  width: 100%;
  height: 8rem;
  padding-left: 6rem;
  box-sizing: border-box;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 4rem;
  font-size: 2.5rem;
}

.login__password--input:focus {
  outline: none !important;
  border: 1.5px solid #d9d8ff;
}

.password--input {
  padding-left: 5rem;
  width: 429.23px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.password--input:focus {
  outline: none !important;
  border: 1px solid #f95959;
}

.login__submit--CTA {
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  width: 100%;
  height: 8rem;
  left: 90px;
  top: 612px;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  border-radius: 75px;
  color: #181a49 !important;
  font-weight: 700;
  font-size: 2.5rem;
}

.login__image--container {
  height: 100%;
  padding: 0rem 10rem;
  background-image: url(../../assets/images/login-img-bg.png);
  background-position: center;
  background-size: cover;
  width: 50%;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}

.login__image--box {
  width: 90%;
  height: auto;
}

.login__main--text {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  margin-top: 7rem;
  margin-bottom: 2rem;
}

.login__sub--text {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 3rem;
  color: #f8f8f8;
}

.create__comp--CTA-span {
  width: 429.23px;
  display: flex;
  justify-content: center;
}

.login__dont-have--account {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 2.5rem;
}

.sign__up-link {
  font-weight: 800;
  color: #181a49;
}

.error__message {
  font-size: 2.5rem;
}

@media screen and (max-width: 100rem) {
  .login__container {
    height: 100vh;
    background-color: #ffffff;
    display: flex;
  }

  .login__cykea--logo {
    height: 6rem;
    width: 7rem;
  }

  .navLogo-and-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 20%;
    height: 8%;
  }

  .login__form--container {
    width: 50%;
    background: #ffffff;
    padding: 3rem 0 4.5rem 8rem;
  }

  .form-and-text__container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .login__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .mail__icon {
    position: absolute;
    top: 1.5rem;
    left: 2rem;
    right: 0;
    bottom: 0;
  }

  .login__email-and-icon {
    position: relative;
  }

  .login__password-and-icons {
    position: relative;
  }

  .lock__icon {
    position: absolute;
    top: 1.5rem;
    left: 2rem;
    right: 0;
    bottom: 0;
  }

  .login__eye-icon {
    position: absolute;
    height: 1.5rem;
    top: 1.5rem;
    left: 38.5rem;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
  .login__form--texts {
    display: flex;
    flex-direction: column;
    width: 36rem;
  }

  .login__formheading {
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 10rem;
    margin-bottom: 0rem;
  }

  .login__form-sub--text {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #858585;
  }

  .login__email--label {
    font-weight: 500;
    font-size: 12px;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .login__password--label {
    font-weight: 500;
    font-size: 12px;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .login__email--input {
    width: 429.23px;
    height: 48px;
    padding-left: 3rem;
    box-sizing: border-box;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 4rem;
    font-size: 1.2rem;
  }
  .email--input {
    width: 429.23px;
    height: 48px;
    padding-left: 3rem;
    background: #ffffff;
    border: 0.8px solid #e5e5e5;
    border-radius: 4rem;
    box-sizing: border-box;
    font-size: 1.2rem;
  }

  .forgot__passwordone {
    color: #655d8a;
    margin-top: 3rem;
    font-size: 1.2rem;
    cursor: pointer;
    width: fit-content;
  }

  .email--input:focus {
    outline: none !important;
    border: 1px solid #f95959;
  }
  .login__email--input:focus {
    outline: none !important;
    border: 1.5px solid #d9d8ff;
    background: #ffffff !important;
  }

  .login__password--input {
    padding-left: 3rem;
    width: 429.23px;
    height: 48px;
    background: #ffffff;
    border: 0.8px solid #e5e5e5;
    border-radius: 4rem;
    box-sizing: border-box;
    font-size: 1.2rem;
  }

  .login__password--input:focus {
    outline: none !important;
    border: 1.5px solid #d9d8ff;
    background: #ffffff !important;
  }

  .password--input {
    padding-left: 5rem;
    width: 429.23px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .password--input:focus {
    outline: none !important;
    border: 1px solid #f95959;
  }

  .login__submit--CTA {
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    width: 429.23px;
    height: 56px;
    left: 90px;
    top: 612px;
    border-radius: 4rem;
    color: #181a49 !important;
    font-weight: 700;
    font-size: 1.6rem;
  }

  .login__image--container {
    height: 100%;
    padding: 0rem 10rem;
    width: 50%;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
  }

  .login__image--box {
    width: 90%;
    height: auto;
  }

  .login__main--text {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
    text-align: center;
    color: #ffffff;
    margin-top: 7rem;
    margin-bottom: 2rem;
  }

  .login__sub--text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 3rem;
    color: #f8f8f8;
  }

  .create__comp--CTA-span {
    width: 429.23px;
    display: flex;
    justify-content: center;
  }

  .login__dont-have--account {
    display: flex;
    justify-content: center;
    width: 429.23px;
    font-size: 1.5rem !important;
  }

  .sign__up-link {
    font-weight: 800;
  }

  .error__message {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 77rem) {
  .login__image--container {
    padding: 0rem;
  }
}

@media screen and (max-width: 64rem) /*104/16 = 65*/ {
  .login__container {
    height: fit-content;
  }

  .login__content--container {
    display: flex;
  }

  .login__image--container {
    width: 50%;
    padding: 0rem;
  }

  .login__form--container {
    padding: 3rem 3rem 0 6rem;
  }

  .login__formheading {
    font-weight: 700;
    font-size: 2.4rem;
  }

  .login__email--input {
    width: 360.23px;
  }
  .email--input {
    width: 360.23px;
  }

  .login__password--input {
    width: 360.23px;
  }
  .password--input {
    width: 360.23px;
  }

  .login__dont-have--account {
    width: 360.23px;
  }

  .login__submit--CTA {
    width: 360.23px;
  }

  .create__comp--CTA-span {
    width: 360.23px;
    display: flex;
    justify-content: center;
  }

  .login__eye-icon {
    left: 32.5rem;
  }
}
@media screen and (max-width: 54.8rem) /*877/16 = 65*/ {
  .login__content--container {
    display: block;
  }

  .login__form--container {
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;
  }

  .login__formheading {
    text-align: left;
  }

  .login__image--container {
    display: none;
  }

  .form-and-text__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .create__comp--acc {
    width: 76%;
  }
}
@media screen and (max-width: 29rem) /*877/16 = 65*/ {
  .login__form--container {
    padding: 3rem 3rem 2rem 2rem;
    height: 70vh;
  }

  .login__content--container {
    display: block;
  }

  .login__form--container {
    width: 100%;
    height: 100%;
  }

  .login__email--input {
    width: 300.23px;
  }
  .email--input {
    width: 300.23px;
  }

  .login__password--input {
    width: 300.23px;
  }
  .password--input {
    width: 300.23px;
  }

  .login__submit--CTA {
    width: 300.23px;
  }

  .login__dont-have--account {
    width: 300.23px;
  }

  .create__comp--CTA-span {
    width: 392.23px;
  }

  .login__eye-icon {
    left: 26.5rem;
  }

  .login__image--container {
    display: none;
  }

  .login__form--texts {
    width: 30rem;
  }

  .form-and-text__container {
    display: flex;
  }

  .mobile__view {
    display: block;
  }
}
