.pracQuestion-accordion__children-container {
  /* background-color: aqua; */
  background-color: #ffffff;
}

.task__description-text {
  color: #181a49;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin-bottom: 2.5rem;
}

.question__instruction {
  color: #807d9e;
  font-size: 12px;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 1rem;
}

.practical__questions-box {
  background-color: #ffffff;
  /* background-color: chartreuse; */
}

.practical__question-div {
  margin-bottom: 2.5rem;
}

.practical__question-box {
  background-color: #ffffff;
  border: 1px solid #eae2ff;
  border-radius: 7.2rem;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  cursor: pointer;
}
.practical__question-box--done {
  border-radius: 7.2rem;
  background: var(
    --linerr,
    linear-gradient(243deg, #eae2ff 0%, rgba(187, 221, 252, 0.28) 100%)
  );
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  cursor: pointer;
}

.practical__question {
  color: #181a49;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 1.5rem;
}

.prac__question-arrow {
  position: absolute;
  top: 0.5rem;
  right: 4px;
}

.answer__input-and-complete--CTA {
  width: 87%;
  /* background-color: brown; */
  display: flex;
  position: relative;
}

.prac__question-answer--input {
  height: 4.3rem;
  width: 100%;
  /* background: #f7f7f7; */
  border: 1px solid #eae2ff;
  border-radius: 10px;
  margin-right: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 5.4rem;
}

.content__completed-tag {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  color: #a734dd;
}

.pracQuestion__complete-CTA {
  padding: 0.8rem 2.7rem;
  border-radius: 40px;
  background: linear-gradient(
    243deg,
    #eae2ff 0%,
    rgba(187, 221, 252, 0.28) 100%
  );
  position: absolute;
  right: 2rem;
  top: 0.5rem;
  font-style: normal;
  font-weight: 500;
  color: #181a49;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.correct__answer-and-icon {
  padding: 0.8rem 1.5rem;
  width: fit-content;
  border-radius: 40px;
  /* background: linear-gradient(
    243deg,
    #eae2ff 0%,
    rgba(187, 221, 252, 0.28) 100%
  ); */
  position: absolute;
  right: 2rem;
  top: 0.4rem;
  font-style: normal;
  font-weight: 500;
  color: #181a49;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  border: 1px solid #eae2ff;
}

.correct__answer-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #07532a;
  margin-right: 1rem;
}

@media screen and (max-width: 72rem) {
  .prac__question-answer--input {
    width: 100%;
  }

  .answer__input-and-complete--CTA {
    width: 100%;
  }
}
@media screen and (max-width: 48rem) {
  .correct__answer-text {
    font-size: 1.1rem;
  }
  .task__description-text {
    font-size: 1.2rem;
  }

  .practical__question {
    font-size: 1.2rem;
  }
}
