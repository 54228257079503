.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  background: #0f0f0f;
  display: flex;
  flex-direction: column;
  z-index: 100000;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.content__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
  display: flex;
  flex-direction: column;
  z-index: 100000;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  border-radius: 0.9rem;
}

.loader__circle {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  padding: 1.5rem;
  background: var(
    --cykea-linear-main,
    linear-gradient(266deg, #e6dcff 35.33%, rgba(154, 204, 251, 0.72) 121.98%)
  );
  animation: rotate 2s linear infinite;
  margin-bottom: 2rem;
}

.loader__inner-circle {
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: #0f0f0f;
  border-radius: 50%;
}

.content__loader-inner--circle {
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: #fff;
  border-radius: 50%;
}

.custom__loader-text {
  color: #fff;
  /* color: #edf0ff; */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
}

.content__loader-custom__loader--text {
  color: #181a49;
  /* color: #edf0ff; */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 48rem) {
  .loader__circle {
    width: 5rem;
    height: 5rem;
    padding: 0.8rem;
  }

  .custom__loader-text {
    font-size: 1.2rem;
  }

  .content__loader-custom__loader--text {
    font-size: 1.2rem;
  }
}
