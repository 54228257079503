.passed__practical-question--container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8.7rem 2rem 16rem 2rem;
  border-radius: 1.5rem;
  border-radius: 15px;
  background: #edf0ff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
}

.passed__component-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  /* background-color: red; */
}

.completed__task-icon {
  margin-bottom: 5.8rem;
}

.completed__task-text {
  color: var(--cykea-main, #181a49);
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.passed__container-text {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #171531;
  /* margin-bottom: 5.8rem; */
}

.retry-CTA {
  width: 115px;
  height: 56px;
  border: 1.5px solid #e5e5e5;
  border-radius: 10px;
  margin-right: 2rem;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #949699;
}

.continue-CTA {
  width: 160px;
  height: 56px;
  background: #a734dd;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.passed__container-CTA--box {
  width: 100%;
  display: flex;
  justify-content: center;
}
