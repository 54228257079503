.userDetails__Bar--container {
  border-bottom: 1px solid #f2f5f9;
  /* background-color: aqua; */
  padding: 1.8rem 8rem 1.9rem 4.1rem;
  height: 5.9rem;
  background: #ffffff;
  position: fixed;
  width: 83.5%;
  z-index: 900;
}

.logout__CTA-and-text {
  display: none;
}

.hide__mobile--title {
  display: none;
}

.logout__text {
  display: block;
  margin: 0 1.3rem;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #a90f0f;
}

.userDetails__content--container {
  /* background-color: blueviolet; */
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mobile__back--nav {
  display: none;
}

.currentLink-and-title {
  /* background-color: tomato;   */
  display: flex;
  align-items: center;
}

.series__and__details {
  display: flex;
}

.current__link {
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  font-weight: 300;
  color: #858585;
}

.current__series {
  /* margin-left: 2rem; */
  font-weight: 400;
  font-size: clamp(1.2rem, 1.4vw, 1.4rem);
  color: #171531;
}

.current__title {
  font-style: normal;
  font-weight: normal;
  font-size: (1.5rem);
  line-height: 1.9rem;
  color: black;
}

.currentLink-and-title > svg {
  width: 1rem;
  height: 1rem;
  margin: 0 2rem;
}

.page__heading {
  font-style: normal;
  font-size: clamp(1.6rem, 2vw, 2rem);
  font-weight: 400;
  line-height: 25px;
  color: #171531;
}

.notification-and-userName {
  /* background-color: chartreuse; */
  display: flex;
  width: 50%;
  align-items: flex-end;
  justify-content: flex-end;
}

.notification-icon-and-number {
  /* background-color: rebeccapurple; */
  display: flex;
  width: fit-content;
  position: relative;
  margin-right: 6rem;
}

.notification-icon-and-number > svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #655d8a;
}

.notification__number {
  font-size: 1rem;
  height: 1.6rem;
  width: 1.6rem;
  color: #655d8a;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #efdfe5;
  position: absolute;
  top: -0.8rem;
  left: 0.6rem;
  right: 0;
  bottom: 0;
}

.logout-and-profile__link-CTAs {
  position: absolute;
  top: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  background: #ffffff;
  /* background: red; */
  border: 0.8px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgba(217, 217, 217, 0.1);
  border-radius: 15px;
  padding: 2rem;
  cursor: pointer;
}

.dropdown__prompts--text {
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  color: #949699;
  margin-left: 2rem;
}

.hide {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: red; */

  width: fit-content;
  position: relative;
}

.accessibility__icon {
  margin-left: 3rem;
  display: flex;
  cursor: pointer;
}
.feedback__icon {
  margin-left: 3rem;
  cursor: pointer;
}

.username__abbr {
  height: 2.4rem;
  width: 2.4rem;
  color: #171531;
  font-size: 10px;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(
    --linner-3,
    linear-gradient(243deg, #eae2ff 0%, rgba(187, 221, 252, 0.28) 100%)
  );
  margin-left: 3rem;
  cursor: pointer;
}

.username-and-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: red; */
  border-left: 1px solid #f2f5f9;
  border-right: 1px solid #f2f5f9;
  width: 17rem;
  position: relative;
  z-index: 20;
}

.downarrow__in__detailsBar {
  width: 1rem;
  height: 1rem;
  margin-left: 1.3rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.downarrow__in__detailsBar--flipped {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin-left: 1.3rem;
  transform: rotateX(180deg);
  transition: 0.3s ease-in-out;
}

.username {
  margin-right: 1rem;
  font-weight: 600;
  font-size: 1rem;
}

.username::first-letter {
  text-transform: capitalize;
}

.show__heading {
  display: none;
}
.show__menu--CTA {
  display: none;
}

.hide__name {
  display: block;
}

.mobile__menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  transition: 0.2s ease-in-out;
}

.hide-for-desktop {
  display: none;
}

.available__certificate-banner {
  display: none;
}

@media screen and (max-width: 62.5rem) /*768/16*/ {
  /* .username-and-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      background: red;
      border-left: 1px solid #f2f5f9;
      border-right: 1px solid #f2f5f9;
      width: 27em;
    } */
  .notification-and-userName {
    width: auto;
  }

  .hide {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */

    /* width: 17rem; */
  }
}

@media screen and (max-width: 48rem) /*768/16*/ {
  .userDetails__Bar--container {
    border-bottom: 1px solid #f2f5f9;
    /* background-color: aqua; */
    padding: 2.2rem 1rem 2.2rem 2.2rem;
    height: fit-content;
    width: 100%;
    transition: 0.2s ease-in-out;
  }

  .mobile__back--nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hide {
    display: none;
  }

  .hide__mobile--title {
    display: block;
  }

  .hide__name {
    display: none;
  }

  .show__menu--CTA {
    display: block;
    cursor: pointer;
  }
  .userDetails__content--container {
    /* background-color: blueviolet; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hide__mobile-menu {
    height: 0;
    transition: 0.2s ease-in-out;
  }

  .show__heading {
    display: block;
  }

  .notification-and-userName {
    align-items: center;
  }

  .username-and-arrow {
    width: 12rem;
  }

  .hide-notification {
    display: none;
  }

  .notification-icon-and-number > svg {
    width: 2.5rem;
    height: 2.5rem;
    /* fill: #655d8a; */
  }

  .logout__CTA-and-text {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: auto;
    font-size: 1px;
    height: 42px;
    left: 0px;
    top: 0px;
    background: #fdfdfd;
    border: 1px solid #f2f5f9;
    border-radius: 8px;
  }

  .logout-and-profile__link-CTAs {
    left: -4rem;
    width: 14rem;
  }

  .currentLink-and-title {
    /* background-color: tomato;   */
    display: none;
    /* align-items: center; */
  }

  /* .link__and__icon {
      flex-direction: row;
      margin-bottom: 2.5rem;
    } */

  .hide-for-desktop {
    display: flex;
    margin-bottom: 0rem;
    padding: 1rem 1.5rem;
  }

  .available__certificate-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.9rem 1rem;
    margin-bottom: 3rem;
    background: #edf0ff;
  }

  .certificate__available-and-badge--span {
    display: flex;
    align-items: center;
  }

  .certificate__available-text {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 1rem;
  }

  .available__cert-banner--CTA {
    color: #181a49;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    border: 0.125px solid var(--Linner_3, #eae2ff);
    background: var(
      --cykea-linear-main,
      linear-gradient(266deg, #e6dcff 35.33%, rgba(154, 204, 251, 0.72) 121.98%)
    );
  }
}
@media screen and (max-width: 26rem) /*414/16*/ {
  .series__and__details {
    display: none;
  }
}
