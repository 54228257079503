.createCompLogin__container {
  height: fit-content;
}

.createCompLogin__content--container {
  height: fit-content;
  display: flex;
}

.overlay {
  background-color: rgba(101, 93, 138, 0.452);
  position: absolute;
  height: 149%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  z-index: 1;
}

.createCompAccNavLogo-and-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  height: 8%;
}

.comp__acc--success {
  height: 100vh;
  background: #efdfe5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success__text-and-login__CTA {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 20%;
  width: fit-content;
  font-size: 3rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0 2rem;
}
.success__text {
  background-image: linear-gradient(to bottom, #655d8a, #efdfe5);
  background-clip: text;
  color: transparent;
}
.goToLogin__CTA {
  background-color: #655d8a;
  color: #efdfe5;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

button:disabled,
button[disabled] {
  background-color: #cccccc !important;
  color: #666666;
  cursor: no-drop;
}

.createCompLogin__form--container {
  width: 50%;
  padding: 3rem 0 0 8rem;
}

.forgot__password {
  margin-top: 3rem;
  color: #d885a3;
  font-size: 1.2rem;
  cursor: pointer;
  width: fit-content;
}

.dont__have-account {
  font-size: 1.2rem;
  color: #858585;
  margin: 3rem 0;
  text-align: center;
  width: 429.23px;
}

.form-and-text__container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.createCompLogin__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.mail__icon {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  right: 0;
  bottom: 0;
  fill: "#0000006e";
}

.createCompLogin__email-and-icon {
  position: relative;
}

.createCompLogin__password-and-icons {
  position: relative;
}

.lock__icon {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  right: 0;
  bottom: 0;
}

.errorMessage {
  color: #cc2442;
  margin: 1rem 0;
  font-size: 1.1rem;
}

.instruction {
  color: #cc2442;
  margin: 1rem 0;
  display: block;
  font-size: 1.1rem;
  font-size: 1.1rem;
}

.offScreen {
  position: absolute;
  top: -100000rem;
}

.eye__icon {
  position: absolute;
  top: 1.5rem;
  left: 38.5rem;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.createCompLogin__form--texts {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.createCompLogin__formheading {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 1rem;
  color: black;
}

.createCompLogin__form-sub--text {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #858585;
}

.createCompLogin__email--label {
  font-weight: 300;
  font-size: 12px;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.createCompLogin__password--label {
  font-weight: 300;
  font-size: 12px;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.createCompLogin__email--input {
  width: 429.23px;
  height: 48px;
  padding-left: 2rem;
  box-sizing: border-box;
  background: #ffffff;
  border: 0.8px solid #e5e5e5;
  border-radius: 10px;
}
.email--input {
  width: 429.23px;
  height: 48px;
  padding-left: 2rem;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
}

.email--input:focus {
  outline: none !important;
  border: 1px solid #f95959;
  border-radius: 10px;
}
.createCompLogin__email--input:focus {
  outline: none !important;
  border: 1px solid #d885a3;
  border-radius: 10px;
}

.createCompLogin__password--input {
  padding-left: 5rem;
  width: 429.23px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.createCompLogin__password--input:focus {
  outline: none !important;
  border: 1px solid #d885a3;
  border-radius: 4px;
}

.password--input {
  padding-left: 5rem;
  width: 429.23px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.password--input:focus {
  outline: none !important;
  border: 1px solid #f95959;
  border-radius: 4px;
}

.createCompLogin__submit--CTA {
  width: 429.23px;
  height: 56px;
  background-image: linear-gradient(
    266.17deg,
    #eae2ff 35.33%,
    rgba(187, 221, 252, 0.28) 121.98%
  ) !important;
  border-radius: 15px;
  margin: 4rem 0;
}

.create__comp--acc {
  width: 55%;
  align-self: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: transparent;
  border: 1px solid #655d8a;
  color: #655d8a;
}

.createCompLogin__image--container {
  height: 100%;
  background-image: url(../../assets/images/reg-bg-img.png);
  background-position: center;
  background-size: cover;
  width: 50%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 9rem 2rem 12rem 1rem;
}

.createCompLogin__main--text {
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  margin-top: 7rem;
  margin-bottom: 2rem;
}

.createCompLogin__sub--text {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 3rem;

  color: #f8f8f8;
}

@media screen and (max-width: 65rem) /*104/16 = 65*/ {
  .createCompLogin__form--container {
    padding: 3rem 3rem 0 6rem;
  }

  .createCompLogin__email--input {
    width: 360.23px;
  }
  .email--input {
    width: 360.23px;
  }

  .createCompLogin__submit--CTA {
    width: 360.23px;
  }

  .createCompLogin__password--input {
    width: 360.23px;
  }
  .password--input {
    width: 360.23px;
  }

  .createCompLogin__submit--CTA {
    width: 360.23px;
  }

  .dont__have-account {
    width: 360.23px;
  }

  .eye__icon {
    left: 32.5rem;
  }
}
@media screen and (max-width: 54.8rem) /*877/16 = 65*/ {
  .createCompLogin__content--container {
    display: block;
  }

  .createCompLogin__form--container {
    width: 100%;
    height: 100%;
  }

  .createCompLogin__image--container {
    display: none;
  }

  .form-and-text__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (max-width: 28.8rem) /*877/16 = 65*/ {
  .createCompLogin__form--container {
    padding: 3rem 3rem 0 2rem;
    height: 70vh;
  }

  .createCompLogin__content--container {
    display: block;
  }

  .createCompLogin__form--container {
    width: 100%;
    height: 100%;
  }

  .createCompLogin__email--input {
    width: 300.23px;
  }
  .email--input {
    width: 300.23px;
  }

  .createCompLogin__password--input {
    width: 300.23px;
  }
  .password--input {
    width: 300.23px;
  }

  .createCompLogin__submit--CTA {
    width: 300.23px;
  }

  .createCompLogin__submit--CTA {
    width: 300.23px;
  }

  .eye__icon {
    left: 26.5rem;
  }

  .createCompLogin__image--container {
    display: none;
  }

  .form-and-text__container {
    display: flex;
  }
}
