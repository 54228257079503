.landing__page-container {
  /* padding-top: 3.3rem; */
}

.mobile__nav-content--container {
  display: flex;
  flex-direction: column;
  padding: 3.5rem 2.7rem 0 2.7rem;
}

.close__icon-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(154, 204, 251, 0.72);
  padding-bottom: 4rem;
  margin-bottom: 4rem;
}

.mobile__nav-link--text {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  margin-bottom: 3rem;
  color: #181a49;
}

.contact__us-span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.last__link {
  margin-bottom: 0rem;
}

.contact__us-link {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  color: rgba(24, 26, 73, 0.5);
  margin-bottom: 2rem;
}

.contact__social-icon {
  fill: #181a49 !important;
  height: 21px;
  width: 17px;
}
