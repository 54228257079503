.floating__access__container {
  width: 30.2rem;
  background-color: #ffffff;
  height: fit-content;
  position: fixed;
  left: 0;
  /* top: 20rem; */
  bottom: 0;
  right: 0;
  z-index: 1000000000000000;
  display: flex;
  flex-direction: column;
  padding: 4.2rem 2.5rem 1.5rem 2.5rem;
  border: 1px solid rgba(196, 174, 255, 0.5);
  box-shadow: 0px 7px 30px 0px #f2e8ff66;
  border-radius: 3.7rem;
}

.floating__access__new__icon {
  position: absolute;
  top: -2rem;
  left: 0;
}

.floating__access__main__text {
  font-family: Urbanist;
  font-size: 2.4rem;
  font-weight: 800;
  text-align: left;
  color: #181a49;
  margin-bottom: 1rem;
}

.floating__access__sub__text {
  font-family: Red Hat Display;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 2rem;
  color: #6b6790;
}

.enroll__now__CTA {
  background-image: linear-gradient(
    266.17deg,
    #eae2ff 35.33%,
    rgba(187, 221, 252, 0.28) 121.98%
  );
  font-family: Red Hat Display;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 21.17px;
  text-align: left;
  height: 5.5rem;
  width: 14.8rem;
  text-align: center;
  color: #181a49;
  border: 1.5px solid #eeefff;
  border-radius: 8rem;
}

@media screen and (min-width: 62.5rem) {
  .floating__access__container {
    top: 20rem;
  }
}
