.expired__sub--div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  overflow: hidden;
}

.expired__sub--overlay {
  background: rgb(0 0 0 / 61%);
  /* opacity: 0.7; */
  z-index: 100000;
  /* backdrop-filter: blur(0.7rem);
  -webkit-backdrop-filter: blur(0.7rem); */
  overflow: hidden;
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expired__sub--container {
  width: 527px;
  /* height: 320px; */
  background: #ffffff;
  border: 0.7px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgba(212, 212, 212, 0.1);
  border-radius: 15px;
  padding: 4.1rem 4.2rem 4rem 4.2rem;
}

.forgot__password-heading-and-close--CTA {
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: #03a200; */
}

.forgot__password-and-close {
  display: flex;
  /* background-color: red; */
  width: 100%;
  margin-bottom: 3.8rem;
}

.forgot__pword {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #171531;
  margin-bottom: 1rem;
  text-align: center;
}

.reset__pword-sub--text {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #858585;
  margin-bottom: 2.9rem;
}

.forgot__pWord-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: #757575;
}

.forgot__pword-email {
  width: 429.23px;
  height: 48px;
  background: #ffffff;
  border: 0.7px solid #e5e5e5;
  border-radius: 10px;
  display: block;
  margin-top: 1rem;
  /* margin-bottom: 3.5rem; */
  padding-left: 1rem;
}

.send__reset-email--CTA {
  width: 429.23px;
  height: 56px;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  border-radius: 75px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #181a49;
  text-align: center;
}

.forgot__pword-cykeaLogo-and-back {
  display: none;
}

.success__message {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* color: #03a200; */
  margin-bottom: 2rem;
}

@media screen and (max-width: 45rem) {
  .expired__sub--container {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding-left: 2.7rem;
    padding-right: 2.7rem;
  }

  .forgot__pword-email {
    width: 100%;
  }

  .renew__sub--CTA {
    width: 10%;
  }

  .send__reset-email--CTA {
    width: 100%;
  }

  .close__forgot-pword {
    display: none;
  }

  .forgot__pword-cykeaLogo-and-back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;
    /* background-color: red; */
  }

  .forgot-pword__back-and-arrow {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .forgot__pword-back {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
    color: #4a5567;
    margin-left: 1.1rem;
  }
}
