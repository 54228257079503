.section__three-container {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 2.5rem 5rem 2.5rem;
}

.section__three-box--one {
  display: flex;
  flex-direction: column;
}

.section__three-box--two {
  display: flex;
  flex-direction: column;
}
.section__three-text--box {
  flex-grow: 1;
}

.tag__text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #ec91f4;
  text-align: left;
  margin-bottom: 1rem;
}

.section__three-main--text {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 131.77%;
  color: #181a49;
  text-align: left;
  margin-bottom: 1.2rem;
}

.section__three-sub--text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: left;
  color: #807d9e;
  margin-bottom: 5rem;
  max-width: 81rem;
}

.section__three-img {
  margin-bottom: 7rem;
  flex-grow: 1;
}

@media screen and (min-width: 37.56rem) {
  .section__three-container {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7rem 5rem;
  }

  .section__three-box--one {
    display: flex;
    margin-bottom: 5rem;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .section__three-box--two {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  .section__three-img {
    max-width: 51%;
    margin-bottom: 3rem;
  }

  .sectionThree__img-one {
    margin-left: 5rem;
  }
  .sectionThree__img-two {
    margin-right: 5rem;
  }

  .section__three-text--box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (min-width: 48rem) {
}
@media screen and (min-width: 62rem) {
  .section__three-container {
    padding: 7rem 5rem;
  }

  .section__three-box--one {
    margin-bottom: 5rem;
  }

  .sectionThree__img-one {
    margin-left: 5rem;
  }
  .sectionThree__img-two {
    margin-right: 5rem;
  }

  .tag__text {
    font-size: 1.5rem;
  }

  .section__three-main--text {
    font-size: 2.5rem;
  }

  .section__three-sub--text {
    font-size: 1.9rem;
  }
}

@media screen and (min-width: 75rem) {
  .section__three-container {
    padding: 7rem 10rem;
  }
}

@media screen and (min-width: 90rem) {
  .section__three-container {
    padding: 11rem 12rem 4rem 12rem;
  }

  .section__three-img {
    max-width: 48%;
    /* margin-bottom: 3rem; */
  }

  .section__three-box--one {
    margin-bottom: 9rem;
  }

  .sectionThree__img-one {
    margin-left: 10rem;
  }
  .sectionThree__img-two {
    margin-right: 10rem;
  }

  .tag__text {
    font-weight: 800;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 1.5rem;
  }

  .section__three-main--text {
    font-weight: 700;
    font-size: 36px;
    line-height: 131.77%;
    margin-bottom: 1.5rem;
  }

  .section__three-sub--text {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
    max-width: 108rem;
  }
}
@media screen and (min-width: 125rem) /*2000 / 16*/ {
  .section__three-container {
    padding: 15rem 24rem;
  }

  .tag__text {
    font-weight: 800;
    font-size: 24px;
    line-height: 150%;
  }

  .section__three-main--text {
    font-weight: 700;
    font-size: 45px;
    line-height: 131.77%;
  }

  .section__three-sub--text {
    font-weight: 400;
    font-size: 35px;
    line-height: 150%;
    max-width: 177rem;
  }

  .section__three-box--one {
    margin-bottom: 20rem;
  }

  .sectionThree__img-one {
    margin-left: 12rem;
  }
  .sectionThree__img-two {
    margin-right: 12rem;
  }
}
