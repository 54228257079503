.pricing__container {
  /* height: 100vh; */
  background-color: #ffffff;
  padding-top: 25rem;
  padding-bottom: 8.5rem;
  padding-left: 12.8rem;
  padding-right: 12.8rem;
}

.decoy {
  opacity: 0;
}

.course-detail-box--1 {
  margin-right: 2.2rem;
  background-color: red;
  width: 90rem;
}
.course-detail-box--2 {
  /* margin-left: 2.2rem; */
  background-color: orange;
  width: 90rem;
}
.top__half {
  border-bottom: 1px solid #ffeaf2;
}

.pricing__courses-main--container {
  /* display: "flex !import" ; */
  background-color: orange;
  align-items: "flex-start";
  justify-content: "center";
}

.course__tag {
  margin-bottom: 3.3rem;
  width: 16rem;
  height: 5rem;
  display: flex;
  font-size: 2.2rem;
  justify-content: center;
  align-items: center;
  background: #fffcbf;
  border-radius: 20px;
  color: #796602;
}

.course__tag-icon {
  height: 3rem;
  width: 3rem;
}

.pricing__heading {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 5rem;
  line-height: 38px;
  color: #171531;
  text-align: center;
  margin-bottom: 4rem;
}

.from__partner-span {
  /* width: 569px; */
  /* background-color: red; */
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.from__partner {
  /* margin-left: 6rem; */
  width: 220px;
  height: 32px;

  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  /* identical to box height */

  /* Main Text */

  color: #171531;
}

.courses__prices-container {
  background-color: #ffffff;
  display: flex;
  /* height: 100%; */
  justify-content: center;
  align-items: flex-start;
  /* width: 100%; */
}

.course-detail-box--1 {
  margin-bottom: 4.1rem;
}

.course__price-details {
  /* background-color: cadetblue; */
  /* width: 100%; */
  /* height: 788px; */
  background: #ffffff;
  border: 1px solid #ffeaf2;
  box-shadow: 0px 4px 22px rgba(229, 227, 229, 0.1);
  border-radius: 15px;
  padding: 3rem;
}

.bottom__half--heading {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  /* line-height: 15px; */
  color: #171531;
  margin-top: 2rem;
  margin-bottom: 3.2rem;
}

.course__price-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 4rem;
  line-height: 28px;
  color: #171531;
  margin-bottom: 1rem;
}

.course__price-desc {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 7.7rem;
  color: #949699;
  margin-bottom: 3.9rem;
}

.course__content-list--container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.course__price {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 10rem;
  line-height: 63px;
  color: #a734dd;
  margin-bottom: 5.5rem;
}

.course__content-and-icon {
  display: flex;
}

.course__content--text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 5rem;
  color: #949699;
  margin-bottom: 2.2rem;
  margin-left: 1.3rem;
}

.purchase__CTA-span {
  display: flex;
  justify-content: center;
}

.purchase__course-CTA {
  width: 30.1rem;
  height: 8.9rem;
  background: #a734dd;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 2.9rem;
  line-height: 25px;
  color: #ffffff;
}
@media screen and (max-width: 100rem) {
  .pricing__container {
    /* height: 100vh; */
    background-color: #ffffff;
    padding-top: 17rem;
    padding-bottom: 8.5rem;
    padding-left: 12.8rem;
    padding-right: 12.8rem;
  }

  .decoy {
    opacity: 0;
  }

  .course__tag-icon {
    height: 1.4rem;
    width: 1.4rem;
  }

  .course-detail-box--1 {
    margin-right: 2.2rem;
    background-color: red;
    width: 569px;
  }
  .course-detail-box--2 {
    /* margin-left: 2.2rem; */
    background-color: orange;
    width: 569px;
  }
  .top__half {
    border-bottom: 1px solid #ffeaf2;
  }

  .pricing__courses-main--container {
    /* display: "flex !import" ; */
    background-color: orange;
    align-items: "flex-start";
    justify-content: "center";
  }

  .course__tag {
    margin-bottom: 3.3rem;
    width: 106px;
    height: 40px;
    display: flex;
    font-size: 1.2rem;
    justify-content: center;
    align-items: center;
    background: #fffcbf;
    border-radius: 20px;
    color: #796602;
  }

  .pricing__heading {
    font-family: "Sora";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #171531;
    text-align: center;
    margin-bottom: 4rem;
  }

  .from__partner-span {
    /* width: 569px; */
    /* background-color: red; */
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .from__partner {
    /* margin-left: 6rem; */
    width: 220px;
    height: 32px;

    font-family: "Sora";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    /* identical to box height */

    /* Main Text */

    color: #171531;
  }

  .courses__prices-container {
    background-color: #ffffff;
    display: flex;
    /* height: 100%; */
    justify-content: center;
    align-items: flex-start;
    /* width: 100%; */
  }

  .course-detail-box--1 {
    margin-bottom: 4.1rem;
  }

  .course__price-details {
    /* background-color: cadetblue; */
    /* width: 100%; */
    /* height: 788px; */
    background: #ffffff;
    border: 1px solid #ffeaf2;
    box-shadow: 0px 4px 22px rgba(229, 227, 229, 0.1);
    border-radius: 15px;
    padding: 3rem;
  }

  .bottom__half--heading {
    font-family: "Sora";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #171531;
    margin-top: 2rem;
    margin-bottom: 3.2rem;
  }

  .course__price-title {
    font-family: "Sora";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    color: #171531;
    margin-bottom: 1rem;
  }

  .course__price-desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #949699;
    margin-bottom: 3.9rem;
  }

  .course__content-list--container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
  }

  .course__price {
    font-family: "Sora";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 63px;
    color: #a734dd;
    margin-bottom: 5.5rem;
  }

  .course__content-and-icon {
    display: flex;
  }

  .course__content--text {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #949699;
    margin-bottom: 2.2rem;
    margin-left: 1.3rem;
  }

  .purchase__CTA-span {
    display: flex;
    justify-content: center;
  }

  .purchase__course-CTA {
    width: 260px;
    height: 65px;
    background: #a734dd;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
}

@media screen and (max-width: 64rem) {
  .course__content-and-icon {
    min-width: 16.2rem;
  }
}
@media screen and (max-width: 54rem) {
  .course-detail-box--1 {
    margin-right: 1.2rem;
  }
  .course-detail-box--2 {
    margin-left: 1.2rem;
  }

  .course__content--text {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 48rem) {
  .pricing__courses-main--container {
    display: block !important;
  }
  .decoy {
    display: none;
  }

  .pricing__container {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pricing__heading {
    font-size: 1.8rem;
  }

  .course__price-title {
    font-size: 1.6rem;
  }

  .course__price-desc {
    font-size: 1.2rem;
  }

  .course__price-details {
    width: 100%;
  }

  .courses__prices-container {
    flex-direction: column;
  }

  .course-detail-box--1 {
    margin-right: 0rem;
    margin-bottom: 2.1rem;
  }
  .course-detail-box--2 {
    margin-left: 0rem;
    margin-top: 2.1rem;
  }

  .cybarik__pricing {
    margin-top: 4.2rem;
  }
}
@media screen and (max-width: 32rem) {
  .pricing__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .course__price-details {
    width: 100%;
  }

  .courses__prices-container {
    flex-direction: column;
  }

  .course-detail-box--1 {
    margin-right: 0rem !important;
    margin-bottom: 2.1rem;
  }
  .course-detail-box--2 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    margin-top: 2.1rem;
  }

  .course__content-list--container {
    display: block;
  }

  .purchase__CTA-span {
    justify-content: flex-start;
  }

  .course__price {
    font-size: 3.5rem;
  }
}
