.navBar__container {
  width: 100%;
  height: 15.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8.3rem 9rem 5rem 9rem;
  z-index: 10000;
}

.mobile__continue-learning {
  display: none;
}

.nav__enrollNow--CTA {
  display: none;
}
.cykea__landing-logo {
  width: 13.7rem;
  height: auto;
}

.nav__links-span {
  display: flex;
  /* width: 20%; */
  align-items: center;
}

.features-and-arrow {
  display: flex;
  align-items: center;
}

.features__link {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(2rem, 3vw, 3rem);
  line-height: 20px;
  color: #ffffff;
}

.features__link--active {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(2rem, 3vw, 3rem);
  line-height: 20px;
  color: #181a49;
  margin-right: 6rem;
}

.white__bg {
  color: #807d9e;
  font-size: clamp(2rem, 3vw, 3rem);
  font-weight: 600;
  margin-right: 6rem;
}

.nav__CTAs-span {
  display: flex;
  align-items: center;
}

.nav__login-CTA {
  background: #181a49;
  border: 1px solid #ffffff;
  backdrop-filter: blur(50px);
  border-radius: 50px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 2.9rem;
  color: #ffffff;
  padding: 2rem 6rem;
  margin-right: 1.6rem;
}

.nav__getStarted-CTA {
  width: 251px;
  height: 79px;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  border-radius: 75px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #181a49;
}

.active__navbar {
  background-color: #ffffff;
  padding: 2rem 9rem;
  height: 17rem;
  z-index: 10;
  /* transition: all 0.5s ease-in-out; */
  position: fixed;
}

.mobile__nav-content--container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 20;
}

.mobile__menu-CTA {
  display: none;
}

@media screen and (max-width: 100rem) {
  .navBar__container {
    /* position: fixed; */
    width: 100%;
    height: 8.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.3rem 9rem 0 9rem;
  }

  .cykea__landing-logo {
    width: 8.6rem;
    height: auto;
  }

  .white-bg-logo {
    height: 3.9rem;
  }

  .features__link {
    font-size: clamp(1.2rem, 1.2vw, 1.6rem);
  }

  .features__link--active {
    font-size: clamp(1.2rem, 1.2vw, 1.6rem);
  }

  .white__bg {
    color: #807d9e;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: clamp(1.2rem, 1.2vw, 1.6rem);
  }

  /* .nav__links-span {
    display: flex;
    width: 21rem;
    justify-content: space-between;
  } */

  .features__link {
    font-style: normal;
    font-weight: 300;
    font-size: clamp(1.2rem, 1.2vw, 1.6rem);
    line-height: 20px;
    color: #ffffff;
  }

  .nav__login-CTA {
    padding: 1.5rem 2.7rem;
    background: #181a49;
    border: 0.5px solid #ffffff;
    backdrop-filter: blur(100px);
    border-radius: 50px;
    font-style: normal;
    font-size: 15px;
    color: #ffffff;
    margin-right: 1.6rem;
  }

  .nav__getStarted-CTA {
    width: 135px;
    height: 49px;
    border-radius: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 2rem;
    color: #181a49;
  }
}

@media screen and (max-width: 61.4rem) {
  .navBar__container {
    padding: 3.3rem 5rem 0 5rem;
  }

  /* .nav__links-span {
    width: 21.9rem;
  } */

  .nav__login-CTA {
    font-size: 1.1rem;
  }

  .nav__getStarted-CTA {
    width: 112px;
    height: 38px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 48rem) {
  .mobile__continue-learning {
    display: flex;
    justify-content: flex-end;
    background-color: #ecf0ff;
    padding: 1.5rem;
  }

  .mobile__menu-CTA {
    display: block;
  }

  .nav__links-span {
    display: none;
  }

  .nav__CTAs-span {
    display: none;
  }

  .navBar__container {
    padding: 3.3rem 2.6rem 0 2.6rem;
  }

  .nav__enrollNow--CTA {
    display: block;
    width: 111px;
    height: 45px;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 10px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #a734dd;
  }

  .cykea__landing-logo {
    width: 5.7rem;
    height: auto;
  }
}
