.resources-container {
  /* height: 100vh; */
  background-color: #ffffff;
  padding: 7rem 2.5rem;
}

.resources-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 173.5%;
  text-align: center;
  color: #181a49;
  margin-bottom: 3rem;
}

.resources-box--container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* background-color: aqua; */
}

.resources-box {
  /* height: 41rem; */
  width: 28rem;
  border-radius: 4rem;
  margin-bottom: 3.5rem;
  margin-right: 0rem;
  background: #ffffff;
  box-shadow: 5px 14px 40px rgba(200, 200, 200, 0.25);
}

/* .resources-image--half {
  height: 59%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4rem 4rem 0 0;
} */
.resources-image--half {
  /* height: 59%; */
  /* padding: 6rem 11rem; */
  padding: 3rem 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4rem 4rem 0 0;
}

.resources__card-icon {
  width: 16.2rem;
  height: 16.4rem;
}

.s__box-1 {
  background-color: #fee5f5;
}
.s__box-2 {
  background: #d1d3ff;
}
.s__box-3 {
  background: #c7f1ff;
}
.s__box-4 {
  background: #f1ffd3;
}

.resources-text--half {
  height: 40%;
  /* background-color: #ffffff; */
  /* background-color: red !important; */
  border-radius: 0 0 4rem 4rem;
  padding: 2.5rem;
}

.resources-text-and-coming--span {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
}

.resources-date {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 24px;
  color: #45494f;
}

.resources-coming--soon {
  background: #fde4ff;
  padding: 0.5rem 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 13px;
  color: #181a49;
  border-radius: 1.7rem;
}

.resources-card--main-text {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 143%;
  color: #1b0031;
}

@media screen and (min-width: 37.56rem) {
  .resources-container {
    padding: 7rem 0rem 9rem 4.5rem;
  }
  .resources-box {
    margin-right: 4rem;
  }
}

@media screen and (min-width: 48rem) {
}
@media screen and (min-width: 53rem) {
  .resources-box--container {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 62rem) {
  .resources-heading {
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 173.5%;
    margin-bottom: 11rem;
  }

  .resources-box {
    width: 30.5rem;
    /* height: 50rem; */
    border-radius: 4rem;
    margin-bottom: 4rem;
    margin-right: 3rem;
  }

  .resources-text--half {
    /* height: 37%; */
    /* padding: 6rem 10rem; */
    background-color: #ffffff;
    border-radius: 0 0 4rem 4rem;
  }

  .resources-date {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
  }

  .resources-coming--soon {
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .resources-card--main-text {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 143%;
  }
}

@media screen and (min-width: 75rem) {
  .resources-box {
    width: 33.9rem;
    /* height: 50rem; */
    border-radius: 4rem;
    margin-bottom: 5rem;
    margin-right: 3rem;
  }

  .resources-image--half {
    /* height: 59%; */
    /* padding: 6rem 11rem; */
    padding: 7rem 8rem;
  }

  .resources__card-icon {
    height: 18rem;
    width: 18rem;
  }
}

@media screen and (min-width: 90rem) {
  .resources-heading {
    font-weight: 700;
    line-height: 173.5%;
  }

  .resources-box {
    width: 38.9rem;
    height: 56rem;
    border-radius: 4rem;
    margin-bottom: 5rem;
    margin-right: 5rem;
  }

  .resources-text--half {
    padding: 3rem;
  }

  .resources-date {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
  }

  .resources-coming--soon {
    padding: 0.8rem 1rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .resources-card--main-text {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 143%;
  }
}
@media screen and (min-width: 125rem) /*2000 / 16*/ {
  .resources-container {
    padding: 15rem;
    padding-top: 20rem;
  }
  .resources-heading {
    font-weight: 700;
    font-size: 5rem;
    line-height: 173.5%;
    margin-bottom: 10rem;
  }

  .resources-box {
    width: 695px;
    height: 816px;
    margin-bottom: 8rem;
    margin-right: 10rem;
  }

  /* .resources-image--half {
    height: 55%;
  } */

  .resources__card-icon {
    height: 34rem;
    width: 33rem;
  }

  .resources-date {
    font-weight: 5 00;
    font-size: 3.2rem;
    line-height: 4rem;
  }

  .resources-card--main-text {
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 143%;
  }

  .resources-coming--soon {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
}
