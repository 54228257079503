.sectionTwo__container {
  background-image: url(../../assets/images/cy-new-section-two-bg.png);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem 5.9rem 0;
}

.section__two-img {
  margin-bottom: 4.7rem;
  height: auto;
  width: 31rem;
}

.sectionTwo__text-container {
  padding: 0 0 0 4rem;
}

.sectionTwo__main-text {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 48.77%;
  margin-bottom: 2rem;
  color: #eae2ff;
}

.new__icon-and-text {
  display: flex;
  align-items: center;
}

.new__icon {
  margin-right: 0.5rem;
  width: 44px;
  height: 44px;
}

.sectionTwo__sub-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  margin-bottom: 3.5rem;
}

.sectionTwo__CTA {
  background: #ffffff;
  border-radius: 6.6rem;
  padding: 1.6rem 2.9rem;
  font-style: normal;
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 19px;
  color: #181a49;
}

@media screen and (min-width: 37.56rem) {
  .sectionTwo__container {
    flex-direction: row;
    padding: 4rem 2rem 5.9rem 2rem;
  }

  .section__two-img {
    margin-bottom: 0;
  }

  /* .sectionTwo__sub-text {
    max-width: 80%;
  } */
}

@media screen and (min-width: 48rem) {
  .sectionTwo__container {
    padding: 4rem 7rem 5.9rem 2rem;
  }

  .sectionTwo__text-container {
    padding: 0 0 0 7rem;
  }
}
@media screen and (min-width: 62rem) {
  .sectionTwo__container {
    padding: 5rem 18rem 5rem 5rem;
  }

  .section__two-img {
    width: 40.5rem;
    height: auto;
    margin-bottom: 0;
  }

  .sectionTwo__main-text {
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 65%;
    margin-bottom: 3rem;
  }

  .sectionTwo__sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 160%;
  }

  .new__icon {
    height: 5.7rem;
    width: 5.8rem;
    margin-right: 1rem;
  }
}

@media screen and (min-width: 75rem) {
  .sectionTwo__container {
    padding: 5rem 13rem 5rem 10rem;
  }

  .section__two-img {
    width: 481px;
    height: auto;
  }

  .sectionTwo__main-text {
    font-style: normal;
    font-weight: 700;
    font-size: 6rem;
    line-height: 49%;
    margin-bottom: 5rem;
  }

  .sectionTwo__sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 160%;
    margin-bottom: 4rem;
  }

  .new__icon {
    height: 9.7rem;
    width: 9.8rem;
    margin-right: 1.5rem;
  }

  .sectionTwo__CTA {
    padding: 2.2rem 4.3rem;
    border-radius: 6.6rem;
    font-weight: 800;
    font-size: 2rem;
    line-height: 26px;
  }
}

@media screen and (min-width: 90rem) {
  .sectionTwo__container {
    padding: 5rem 12rem;
  }
  .section__two-img {
    width: 40%;
  }

  .sectionTwo__sub-text {
    max-width: 55%;
  }
}
@media screen and (min-width: 100rem) /*2000 / 16*/ {
  .sectionTwo__container {
    padding: 12rem 12rem;
  }
}
@media screen and (min-width: 125rem) /*2000 / 16*/ {
  .sectionTwo__container {
    padding: 12rem 12rem;
  }

  .section__two-img {
    width: 96rem;
    height: 114rem;
    margin-right: 19rem;
  }

  .new__icon {
    height: 14.8rem;
    width: 14.9rem;
    margin-right: 2rem;
  }

  .sectionTwo__main-text {
    font-style: normal;
    font-weight: 700;
    font-size: 8rem;
    margin-bottom: 6.6rem;
  }

  .sectionTwo__sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 143.77%;
    margin-bottom: 9.3rem;
    max-width: 77%;
  }

  .sectionTwo__CTA {
    border-radius: 10.2rem;
    padding: 4rem 9.5rem;
    font-style: normal;
    font-weight: 700;
    font-size: 3.2rem;
  }
}
