.expired__sub--div {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  z-index: 1000;
  height: 100%;
  overflow: hidden;
}

.payment__options--div {
}

.expired__sub--overlay {
  background: rgb(10 0 0 / 57%);
  /* backdrop-filter: blur(0.7rem); */
  /* -webkit-backdrop-filter: blur(0.7rem); */
  overflow: hidden;
  height: 100%;
  /* background-color: #fff; */
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
}

.expired__sub--container {
  background-color: #ffff;
  /* width: 36rem; */
  /* height: 39.3rem; */
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(221, 221, 221, 0.2);
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.expired__sub--text {
  color: #181a49;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 3rem 0;
}

.renew__sub--CTA {
  width: 28rem;
  height: 5.6rem;
  margin-bottom: 2rem;
  border-radius: 53rem;
  background: var(
    --linerr-deeper,
    linear-gradient(243deg, #e6dcff 0%, rgba(154, 204, 251, 0.72) 100%)
  );
  color: #181a49;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dont__submit--CTA {
  width: 28rem;
  height: 5.6rem;
  margin-bottom: 1rem;
  background-color: #fff;
  color: #181a49;
  border: 1px solid rgba(154, 204, 251, 0.72);
  border-radius: 4rem;
  color: #181a49;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.close__expiry--span {
  display: flex;
  justify-content: flex-end;
  width: 80%;
}

@media screen and (max-width: 45rem) {
  .expired__sub--container {
    width: 90%;
    height: 33.3rem;
  }

  .expired--two {
    width: 100% !important;
    justify-content: flex-start;
    height: 100vh;
  }

  .renew__sub--CTA {
    width: 90%;
    color: #181a49;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }

  .dont__submit--CTA {
    width: 90%;
    color: #181a49;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: normal;
  }

  .expired__sub--text {
    color: #181a49;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 3rem 0;
  }
}
