.content__accordion-parent--div {
  /* background-color: #4a5567; */
  background: #edf0ff;
  padding: 1.5rem 2rem;
  cursor: pointer;
  height: 106px;
}

.content__title-and-arrowCTA {
  display: flex;
  justify-content: space-between;
  /* background-color: cadetblue; */
}

.content__title {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(1.1rem, 1.4vw, 1.4rem);
  line-height: 18px;
  color: #181a49;
  margin-bottom: 1.5rem;
}

.content__title-two {
  font-weight: 700;
  color: #181a49;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.course__content-duration {
  color: #6b6790;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.accordion__child-container {
  padding: 1.5rem;
  border-bottom: 1px solid #f2f5f9;
  background-color: #fff;
}

.content__check-and-text {
  display: flex;
  width: 100%;
  align-items: center;
}

.accordion__child-text {
  font-style: normal;
  font-weight: 500;
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  color: #4a5567;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-duration {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 1rem;
  color: #6b6790;
}

.module__duration-and-progress--span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.module__progress--span {
  display: flex;
  align-items: center;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #181a49;
}

@media screen and (max-width: 78rem) {
  .content__title {
    font-size: 1.3rem;
  }
}
