.profile__container {
  background: #f8fafc;
  height: fit-content;
  padding: 5rem 5rem 11rem 5rem;
}

.profile__content--container {
  /* background-color: orange; */
  height: 100vh;
  display: flex;
  padding: 2rem 2rem 0 4rem;
}

.profile__details-and-progress {
  /* background-color: orange; */
  width: 100%;
}

.profile__page--heading {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  color: #181a49;
  margin-bottom: 1.5rem;
  margin-top: 8rem;
}

.userDetails--label {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 13px;
  color: #807d9e;
  margin-bottom: 1.2rem;
}

.user__details--input {
  display: flex;
  flex-direction: column;
}

.profile__progress--chart-container {
  /* background-color: #171531; */
  padding-left: 2rem;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileLeaderboard__container {
  background-color: aqua;
  width: 40%;
  margin-left: 2rem;
  height: fit-content;
}

.profile__details--container {
  width: 90%;
  height: 19.3rem;
  background: #ffffff;
  border-radius: 1.5rem;
  display: flex;
  margin-bottom: 3rem;
  box-shadow: 0px 4px 40px rgba(239, 239, 239, 0.74);
}

.profile__settings--span {
  height: 100;
  display: flex;
  align-items: flex-start;
  width: 6rem;
  padding: 1rem;
  border-radius: 1.5rem 0 0 1.5rem;
  justify-content: flex-start;
  /* background-color: aquamarine; */
}

.profile__image-and-points {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem 1rem 2rem 0;
}
.profile__image-and-name {
  display: flex;
  align-items: center;
}

.profile__image {
  width: clamp(6rem, 12vw, 12rem);
  height: clamp(6rem, 12vw, 12rem);
  border-radius: 50%;
  margin-right: 2rem;
  background-color: #efdfe5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(1.6rem, 3vw, 3rem);
  font-weight: 600;
  color: #655d8a;
}

.profile__name-and-email {
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile__name {
  font-style: normal;
  font-weight: 500;
  font-size: clamp(1.2rem, 1.8vw, 1.8rem);
  line-height: 2.2rem;
  color: #171531;
}

.profile__name::first-letter {
  text-transform: capitalize;
  display: block;
}

.profile__company {
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #789395;
}

.profile__email {
  font-style: normal;
  font-weight: normal;
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  line-height: 1.9rem;
  color: #789395;
}

.profile__points-and-level {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  /* background-color: #789395; */
}

.profile__points {
  font-style: normal;
  font-weight: 500;
  font-size: clamp(1.2rem, 1.8vw, 1.8rem);
  line-height: 2.2rem;
  color: #655d8a;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}

.profile__points > svg {
  margin-left: 1rem;
}

.profile__level {
  font-style: normal;
  font-weight: 500;
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  line-height: 1.9rem;
  color: #655d8a;
  display: flex;
  align-items: center;
}

.profile__level > svg {
  margin-left: 1rem;
}

.progress-and-sub__switch {
  display: flex;
  margin: 5rem 0;
}

.subscription__switch {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #655d8a;
}

.progress__switch {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #655d8a;
}

.coming__soon {
  /* background-color: tomato; */
  box-shadow: 0px 4px 40px rgba(239, 239, 239, 0.74);
  height: 43rem;
  width: 90%;
  padding: 2.8rem 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature__coming {
  text-align: center;
  font-weight: 600;
  font-size: clamp(1.3rem, 1.5rem, 1.5vw);
  color: #858585;
  margin-top: 2rem;
}

.sign__and__text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.active__sub--heading {
  margin-bottom: 3rem;
}

.active__plan--desc {
  display: flex;
  align-items: center;
  /* background-color: gold; */
  border: 0.7px solid #f2f5f9;
  border-radius: 1.5rem;
  height: fit-content;
}

.benefits__span {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.active-sub--billing-info {
  /* background-color: chartreuse; */
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 20vh;
  padding: 0 0 2rem 3rem;
  /* justify-content: center; */
  align-items: space-between;
}

.line {
  width: 73%;
  height: 1px;
  background-color: #efdfe5;
  margin-bottom: 1.6rem;
}

.sub__price {
  font-weight: 600;
  font-size: 1.8rem;
  color: #171531;
}

.sub__per--user {
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 2rem;
  color: #989898;
}

.next__sub--charge {
  font-weight: 600;
  font-size: 1.4rem;
  color: #171531;
}

.cancel__subCTA--span {
  width: 100%;
  display: flex;
  /* height: fit-content; */
  justify-content: center;
  /* background-color: cadetblue; */
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.cancel__sub--CTA {
  background: #f95959;
  border: 0.5px solid #ff8484;
  box-shadow: 0px 2px 22px rgba(255, 138, 138, 0.1);
  border-radius: 10px;
  width: 181px;
  height: 45px;
}

@media screen and (max-width: 78rem) /*1136/16*/ {
  .profile__content--container {
    /* background-color: orange; */
    height: 100vh;
    display: block;
    padding: 3rem;
  }
}
@media screen and (max-width: 71rem) /*1136/16*/ {
  .profile__content--container {
    /* background-color: orange; */
    height: 100vh;
    display: block;
    padding: 3rem;
  }

  .profile__details-and-progress {
    width: 100%;
  }

  .profileLeaderboard__container {
    margin-top: 4rem;
    margin-left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 57.8rem) /*1136/16*/ {
  .active__sub--container {
    width: 100%;
  }

  .commin__soon {
    width: 100%;
  }

  .profile__details--container {
    width: 100%;
  }
}
@media screen and (max-width: 38.7rem) /*617/16*/ {
  .profile__container {
    background: #f8fafc;
    height: fit-content;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .userDetails--label {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
  }

  .active__sub--container {
    height: fit-content;
  }
  .active__plan--desc {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .active__sub--benefits {
    width: 100%;
  }

  .active-sub--billing-info {
    /* background-color: chartreuse; */
    /* border-radius: ; */
    width: 100%;
    margin-top: 2rem;
  }

  .profile__page--heading {
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1.5rem;
    margin-top: 8rem;
  }
}
@media screen and (max-width: 28.12rem) /*450/16*/ {
  .profile__image-and-points {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 1rem 2rem 0;
    position: relative;
  }

  .no__display {
    display: none;
  }

  .profile__points-and-level {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 62%;
    top: 3rem;
    left: 8rem;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  .profile__points {
    font-style: normal;
    font-weight: 500;
    font-size: clamp(1.2rem, 1.8vw, 1.8rem);
    line-height: 2.2rem;
    color: #655d8a;
    margin-bottom: 0rem;
    display: flex;
    align-items: center;
  }

  .profile__level {
    font-style: normal;
    font-weight: 500;
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
    line-height: 1.9rem;
    color: #655d8a;
    display: flex;
    align-items: center;
  }

  .active-sub--billing-info {
    /* padding: 0; */
    width: 100%;
  }
}
