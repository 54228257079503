.passed__quiz-container {
  /* background-color: red; */
  border-radius: 15px;
  background: #edf0ff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
  padding: 7.5rem 2rem;
}

.passed__quiz-text {
  color: var(--cykea-main, #181a49);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 132.5%; /* 26.5px */
  margin-bottom: 4.5rem;
}

.quiz__score {
  color: #259d31;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4.5rem;
}

.retry__quiz-CTA {
  color: var(--cykea-main, #181a49);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid rgba(154, 204, 251, 0.72);
  border-radius: 5rem;
  padding: 1rem 3rem;
  margin-right: 1rem;
  background-color: #fff;
}

.continue__from-quiz--CTA {
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );

  color: var(--cykea-main, #181a49);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1rem 3rem;
  border-radius: 5rem;
  border: none;
  margin-left: 1rem;
}
