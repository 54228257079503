.count__down-main {
  background-image: url(../../assets/images/launch-bg-mobile.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-color: red; */
  min-height: 100vh;
}
.count__down-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: red; */
  padding-top: 12rem;
  padding-left: 2rem;
  padding-right: 2rem;
  /* height: 100vh; */
}

.countdown__heading {
  color: #181a49;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 3.5rem;
}

.countdown__time-container {
  display: flex;
  /* background-color: aqua; */
  margin-bottom: 6.5rem;
}

.countdown__box {
  border-radius: 1.8rem;
  background: var(--cykea-main-text, #181a49);
  padding: 1.4rem 2.7rem;
}

.countdown__box:not(:last-child) {
  margin-right: 1rem;
}

.countdown__box-text {
  color: #e6dcff;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

.countdown__num {
  color: #fff;
  text-align: center;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 600;
  /* line-height: normal; */
}

.countdown__CTA {
  color: #181a49;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  border: none;
  padding: 1.2rem 3.3rem;
  border-radius: 9rem;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
}

.countdown__bg-container {
  background-image: url(../../assets/images/launch-bg.png);
  background-position: bottom;
  background-repeat: no-repeat;
}

.seconds__box {
  display: none;
}

@media screen and (min-width: 25rem) {
  .count__down-container {
    padding-top: 20rem;
  }
}
@media screen and (min-width: 28rem) {
  .seconds__box {
    display: block;
  }
}

@media screen and (min-width: 51rem) {
  .count__down-main {
    background-image: url(../../assets/images/launch-bg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 100vh;
  }

  .count__down-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: red; */
    padding-top: 15rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .countdown__heading {
    color: #181a49;
    text-align: center;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 3.5rem;
  }

  .countdown__time-container {
    display: flex;
    /* background-color: aqua; */
    margin-bottom: 6.5rem;
  }

  .countdown__box {
    border-radius: 1.8rem;
    background: var(--cykea-main-text, #181a49);
    padding: 1.2rem 5rem;
  }

  .countdown__box:not(:last-child) {
    margin-right: 2rem;
  }

  .countdown__box-text {
    color: #e6dcff;
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
  }

  .countdown__num {
    color: #fff;
    text-align: center;
    font-size: 5rem;
    font-style: normal;
    font-weight: 600;
    /* line-height: normal; */
  }

  .countdown__CTA {
    color: #181a49;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    padding: 1.8rem 4.5rem;
    border: none;
    border-radius: 9rem;
    background: linear-gradient(
      266.17deg,
      #e6dcff 35.33%,
      rgba(154, 204, 251, 0.72) 121.98%
    );
  }
}
