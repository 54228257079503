.share__to-linkedin {
  height: 100vh;
  /* background-color: aqua; */
  background: #f8fafc;
  padding: 2rem 2rem;
}

.share__to-linkedin--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  /* background-color: red; */
  width: 100%;
  padding-top: 4rem;
}

.share__to-linkedin--heading {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #181a49;
}

.share__to-linkedin--content {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: 30rem;
  overflow-y: scroll;
  width: 100%;
  border-radius: 1.5rem;
  padding-top: 3rem;
  background: #fff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
  margin-bottom: 3rem;
}

.share__to-linkedin--text {
  width: 92%;
  color: #181a49;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 158%;
  text-align: left;
  margin-bottom: 2rem;
}

.share__thumbnail {
  width: 100%;
  height: auto;
}

.share__to-linkedin--CTAs {
}

.share__to-linkedin--CTA {
  border-radius: 40px;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  border: none;
  padding: 1.5rem 2rem;
  color: #181a49;
  font-size: 1.2rem;
  font-weight: 700;
  margin-right: 1.5rem;
}

.back__to-course--CTA {
  border-radius: 40px;
  background: #fff;
  border: 1px solid rgba(154, 204, 251, 0.72);
  padding: 1.5rem 2rem;
  color: #181a49;
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 1.5rem;
}

@media screen and (min-width: 37.56rem) {
  .share__to-linkedin {
    padding: 2rem 3rem;
  }
}
@media screen and (min-width: 48rem) {
  .share__to-linkedin--heading {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .share__to-linkedin--content {
    width: 80%;
  }
}

@media screen and (min-width: 62rem) {
  .share__to-linkedin--content {
    width: 67%;
  }

  .share__to-linkedin--content {
    height: 40rem;
  }

  .share__to-linkedin--text {
    font-size: 1.4rem;
  }

  .share__to-linkedin--CTA {
    font-size: 1.4rem;
  }

  .back__to-course--CTA {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 90rem) {
  .share__to-linkedin--content {
    width: 45%;
  }

  .share__to-linkedin--content {
    height: 40rem;
  }
}
