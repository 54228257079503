.footer__main-container {
  background-image: url(../../assets/images/footer-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 500px; */
  padding: 7rem 13rem 10.8rem 13rem;
}

.footer__content-container {
  /* background-color: orange; */
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.motto-and-logo--container {
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
}

.footer__text {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(2rem, 2vw, 5rem);
  line-height: 156.5%;
  color: #eae2ff;
}

.partnering {
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 156.5%;
  margin-bottom: 1.5rem;
  color: #aa33df;
}

.footer__links-container {
  /* background-color: chartreuse; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.contact__channels-and-links {
  /* background-color: coral; */
  display: flex;
}

.company__links-span {
  margin-right: 12rem;
}

.contact__channels-span {
  margin-right: 12rem;
}

.company__heading {
  font-style: normal;
  font-weight: 600;
  font-size: 5rem;
  line-height: 127%;
  color: #ffffff;
  margin-bottom: 3rem;
}

.contact__channels-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 5rem;
  line-height: 127%;
  color: #ffffff;
  margin-bottom: 3rem;
}

.footer__link {
  margin-bottom: 3rem;
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 150%;
  color: #eae2ff;
}

.footer__social-icons {
  width: 226px;
  display: flex;
  justify-content: space-between;
}

.address__heading {
  font-style: normal;
  font-weight: 600;
  font-size: 5rem;
  line-height: 127%;
  color: #ffffff;
  margin-bottom: 3rem;
}

.footer__address-text {
  margin-bottom: 0;
}

.footer__social-icon {
  height: 32px;
  width: 39px;
  fill: #eae2ff;
}

.footer__logo {
  height: auto;
  width: 225px;
  cursor: pointer;
}

@media screen and (max-width: 100rem) {
  .footer__main-container {
    padding: 7rem 10rem 5rem 10rem;
  }

  .address__heading {
    font-style: normal;
    font-size: 22px;
    line-height: 127%;
    color: #ffffff;
    margin-bottom: 2.5rem;
  }

  .company__heading {
    font-style: normal;
    font-size: 5rem;
    line-height: 127%;
    color: #ffffff;
    margin-bottom: 2.5rem;
  }

  .contact__channels-heading {
    font-style: normal;
    font-size: 22px;
    line-height: 127%;
    color: #ffffff;
    margin-bottom: 2.5rem;
  }

  .footer__link {
    margin-bottom: 2rem;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* color: #fcd6ff; */
  }

  .footer__social-icons {
    width: 122px;
  }

  .footer__social-icon {
    height: 21px;
    width: 17px;
  }

  .footer__logo {
    height: auto;
    width: 125px;
  }
}

.terms__of__use-policy {
  color: #b3d9fc;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 78rem) {
  .footer__main-container {
    padding: 7rem 5rem 5rem 5rem;
  }
}
@media screen and (max-width: 62rem) {
  .footer__content-container {
    flex-direction: column;
  }

  .footer__text {
    margin-bottom: 2rem;
  }

  .terms__of__use-policy {
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 55.6rem) {
  /* .footer__links-container {
    width: 57%;
  } */

  .contact__channels-and-links {
    flex-direction: column-reverse;
  }

  .office__address {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 48rem) {
  .footer__links-container {
    width: 100%;
  }

  .footer__address-text {
    margin-bottom: 3rem;
  }

  /* .motto-and-logo--container {
    display: none;
  } */

  .footer__logo {
    height: auto;
    width: 75.4px;
  }
}
@media screen and (max-width: 38rem) {
  .footer__main-container {
    padding: 7rem 3rem 10.8rem 2rem;
  }

  .footer__links-container {
    width: 100%;
  }

  /* .motto-and-logo--container {
    display: none;
  } */

  .company__heading {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 127%;
  }

  .contact__channels-heading {
    font-style: normal;
    font-size: 18px;
    line-height: 127%;
  }

  .footer__link {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }
}
