@import url("https://fonts.googleapis.com/css2?family=Federo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
.passed__container {
  /* width: 853px; */
  /* height: 727px; */
  /* background: red; */
  border-radius: 15px;
  display: flex;
  /* padding: 0 3rem; */
  /* align-items: center; */
  /* justify-content: space-between; */
  flex-direction: column;
}

.passed__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: aqua; */
  /* height: 34rem; */
  /* overflow-y: scroll;
  overflow-x: hidden; */
  padding: 3rem 2rem 0rem 2rem;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
}

.passedScore {
  color: #6fcf97;
}
.failedScore {
  color: rgb(150, 0, 0);
}

.share__heading {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #171531;
  margin-bottom: 1rem;
}

.social__share--icons {
  border-top: 0.8px solid #f2f5f9;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2.3rem;
  /* background-color: rebeccapurple; */
}

.share__icon--text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #4a5567;
  margin-left: 1.6rem;
}

.logo_link--span {
  display: flex;
  align-items: center;
  margin-bottom: 2.8rem;
}
.question__number-and-close__icon--passed {
  /* background-color: aqua; */
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  align-items: flex-end;
  border-bottom: 1px solid #f2f5f9;
  margin-bottom: 2.4rem;
}

.passed__heading {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 2rem;
  color: #807d9e;
}

.cert__container {
  width: 704px;
  height: 505px;
  left: 164px;
  top: 118px;
  display: flex;
  padding: 1.3rem 0;
  justify-content: center;
  align-items: center;
  background: #fffdf5;
}

.cert__content--container {
  background: #181a49;
  width: 84%;
  min-width: 52rem;
  /* width: 62.5rem; */
  /* height: 485px; */
  border-radius: 3rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 13rem 4rem 4.7rem 4rem;
  background-image: url(../../assets/images/cy-cert-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.motto {
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 9px;
  color: #949699;
  margin-bottom: 1.9rem;
}

.logo-name-and-title {
  display: flex;
  /* background-color: #6fcf97; */
  justify-content: space-between;
  /* flex-direction: column; */
  align-items: center;
}

.cert_of_completion {
  color: #d7e2ff;
  font-family: Federo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 158%;
  margin-bottom: 2rem;
  font-size: clamp(1.9rem, 2vw, 2rem);
}

.holders__name {
  font-family: "Righteous", cursive;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: clamp(1.9rem, 5vw, 5rem);
}

.certificate__description {
  background: linear-gradient(270deg, #e6dcff 0%, #9accfb 83.32%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 158%;
  margin-bottom: 5.5rem;
}

.downloadable__certificate-description {
  color: #9accfb;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 158%;
  margin-bottom: 5.5rem;
}

.cert__course--title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #655d8a;
  margin-bottom: 5.8rem;
}

.cert__user--name {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 2.5rem;
  color: #000000;
}

.cert__desc-and-barge {
  /* background-color: aqua; */
  display: flex;
  height: 67%;
}

.barge {
  /* background-color: #949699; */
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  text-align: center;
  justify-content: space-between;
  align-items: flex-end;
  /* height: 20%; */
}

.mobile__barge {
  display: none;
}

.cert__number {
  font-style: normal;
  font-weight: 400;
  font-size: 7.5px;
  color: #989898;
}

.cert__desc {
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 158%;
  color: #4a5567;

  width: 354px;
}

.desc-and-sign {
  width: 100%;
  height: 100%;
  /* background-color: #655d8a; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sign__and__date {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  align-items: flex-end;
  /* background: aquamarine; */
}

.signature {
  margin-bottom: 0.2rem;
  width: 12.3rem;
}

.mobile__sign {
  margin-bottom: 1.3rem;
  display: none;
}

.sign__and__name {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 6rem;
}

.cert__Ceo {
  color: rgba(215, 226, 255, 0.5);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  width: 100%;
}

.date__ofissuance--span {
  /* border-bottom: 1.5px solid #a734dd; */
  /* background-color: red; */
  /* height: 4rem; */
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
}

.cert__date {
  color: #d7e2ff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cert__date--span {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.cert__barge-icon {
  margin-bottom: 1.5rem;
  width: 78px;
}

.share__and__continue--CTA {
  width: 100%;
  /* border-radius: 0rem 0rem 1.5rem 1.5rem;
  background: #fff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
}

.download__cert--CTA {
  margin-right: 1.2rem;
  width: 8.1rem;
}
.share__cert--CTA {
  margin-left: 1.2rem;
  position: relative;
}

.share__cert-CTA--icon {
  width: 11.9rem;
}

.continue__CTA {
  padding: 0.8rem 3rem;
  border: 1px solid #e5e5e5;
  border-radius: 4rem;
  background: var(
    --linner-3,
    linear-gradient(243deg, #eae2ff 0%, rgba(187, 221, 252, 0.28) 100%)
  );
  color: var(--cykea-main, #181a49);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.share__pop--up {
  width: 165px;
  height: 227px;
  background: #ffffff;
  border: 0.5px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgba(217, 217, 217, 0.2);
  border-radius: 15px;
  position: absolute;
  left: -0.1rem;
  top: -27.2rem;
  padding: 2rem 2rem;
}

.cert__date--text {
  color: rgba(215, 226, 255, 0.5);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: left;
}

.linkedin__share-button {
  border: none;
  display: flex;
  background-color: transparent;
  align-items: center;
  margin-bottom: 2.8rem;
}

.linkedin__share-button--text {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 140%;
  color: #4a5567;
  margin-left: 1.6rem;
}

@media screen and (max-width: 92rem) {
  .passed__container {
    width: 100%;
  }

  .cert__container {
    width: 97%;
  }
}
@media screen and (max-width: 55rem) {
  .passed__container {
    width: 96%;
  }

  .cert__container {
    width: 97%;
  }

  /* .cert__content--container {
    width: 55rem;
  } */
}
@media screen and (max-width: 48rem) {
  .passed__container {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
  }
  .cert__content--container {
    width: 98%;
  }
  .share__and__continue--CTA {
    padding: 2rem 5rem;
  }

  .cert_of_completion {
    font-style: normal;
    font-weight: 400;
    font-size: 12.5px;
    line-height: 16px;
    color: #a3a3a3;
    width: 57%;
  }
}
@media screen and (max-width: 44rem) {
  .passed__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .cert__content--container {
    min-width: 28.2rem;
    padding-left: 5rem;
    padding-right: 5rem;
    /* height: 317px; */
  }

  .passed__heading {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
  }

  .cert__date {
    font-size: 1rem;
  }

  .cert__barge-icon {
    width: 7rem;
  }

  .signature {
    margin-bottom: 0.2rem;
    width: 7.3rem;
  }
  .sign__and__name {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 3rem;
  }
}

@media screen and (max-width: 32rem) {
  .cert__content--container {
    min-width: 28.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    /* height: 317px; */
  }

  .share__and__continue--CTA {
    padding: 2rem 1rem;
  }

  .passed__content {
    padding: 3rem 1rem 0rem 1rem;
  }

  .download__cert--CTA {
    margin-right: 1.2rem;
    width: 6rem;
  }

  .share__cert-CTA--icon {
    width: 8.9rem;
  }

  .continue__CTA {
    padding: 0.8rem 1.5rem;
    border: 1px solid #e5e5e5;
    border-radius: 4rem;
    background: var(
      --linner-3,
      linear-gradient(243deg, #eae2ff 0%, rgba(187, 221, 252, 0.28) 100%)
    );
    color: var(--cykea-main, #181a49);
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 28rem) {
  .cert__content--container {
    min-width: 28.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 8rem;
    /* height: 317px; */
  }

  .passed__content {
    /* height: 25rem; */
  }

  .certificate__description {
    margin-bottom: 3rem;
    font-size: 0.9rem;
  }

  .downloadable__certificate-description {
    margin-bottom: 3rem;
    font-size: 0.9rem;
  }

  .cert__Ceo {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }

  .cert__date {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
  }

  .cert__number {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 4px;
    /* identical to box height */

    /* lightrxt */

    color: #989898;
  }
}
@media screen and (max-width: 26rem) {
  .cert__container {
    width: 351px;
    height: 336px;
  }

  .logo-name-and-title {
    display: flex;
    flex-direction: row;
    height: 0rem;
    align-items: center;
  }

  .desc-and-sign {
    height: 100%;
    margin-right: -3rem;
    /* background-color: #655d8a; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .share__and__continue--CTA {
    /* flex-direction: column; */
  }

  .sign__and__name {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .passed__content {
    width: 90%;
    /* height: 259px; */
  }

  .cert_of_completion {
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    color: #655d8a;
  }

  .cert__course--title {
    font-style: normal;
    font-weight: 600;
    font-size: 8.5px;
    line-height: 11px;
    color: #655d8a;
    margin-bottom: 3.8rem;
  }

  .cert__user--name {
    font-family: "Sora";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #000000;
  }

  .cert__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 4px;
    line-height: 158%;
    /* or 6px */

    /* Main Text 2 */

    color: #4a5567;
  }

  .cert__date--text {
    font-size: 5px;
    font-style: normal;
    font-weight: 400;
  }

  .cert__barge-icon {
    width: 40px;
  }
  .signature {
    /* margin-bottom: 1rem; */
    width: 6rem;
  }

  /* .mobile__sign {
    display: block;
  } */

  .date__ofissuance--span {
    display: block;
  }

  .cert__Ceo {
    font-size: 5px;
    font-style: normal;
    font-weight: 400;
  }

  .cert__date {
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
  }

  .cert__number {
    font-style: normal;
    font-weight: 400;
    font-size: 3px;
    line-height: 4px;
    /* identical to box height */

    /* lightrxt */

    color: #989898;
  }

  .sign__and__date {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* background: aquamarine; */
  }

  /* .date__ofissuance--span {
    border-bottom: 1.5px solid #000000;
    margin-bottom: 1.3rem;
    background-color: red;
    height: 2rem;
    display: flex;
    width: 7rem;
    align-items: center;
  } */

  .cert__desc-and-barge {
    /* background-color: aqua; */
    display: flex;
    /* width: 30rem; */
    height: 67%;
  }
}
@media screen and (max-width: 24rem) {
  .viewport__height {
    overflow-y: visible !important;
    /* overflow-x: hidden !important */
  }

  .question__number-and-close__icon--passed {
    height: 4rem;
  }
}

@media print {
  @media screen and (max-width: 55rem) {
    .passed__container {
      width: 96%;
    }

    .cert__container {
      width: 97%;
    }
  }
  @media screen and (max-width: 48rem) {
    .passed__container {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .cert__content--container {
      width: 98%;
    }
    .share__and__continue--CTA {
      padding: 2rem 5rem;
    }

    .cert_of_completion {
      font-style: normal;
      font-weight: 400;
      font-size: 12.5px;
      line-height: 16px;
      color: #a3a3a3;
      width: 57%;
    }
  }
  @media screen and (max-width: 44rem) {
    .passed__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .cert__content--container {
      min-width: 28.2rem;
      padding-left: 5rem;
      padding-right: 5rem;
    }

    .passed__heading {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
    }

    .cert__date {
      font-size: 1rem;
    }

    .cert__barge-icon {
      width: 7rem;
    }

    .signature {
      margin-bottom: 0.2rem;
      width: 7.3rem;
    }
    .sign__and__name {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 3rem;
    }
  }

  @media screen and (max-width: 32rem) {
    .cert__content--container {
      min-width: 28.2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .share__and__continue--CTA {
      padding: 2rem 1rem;
    }

    .passed__content {
      padding: 3rem 1rem 0rem 1rem;
    }

    .download__cert--CTA {
      margin-right: 1.2rem;
      width: 6rem;
    }

    .share__cert-CTA--icon {
      width: 8.9rem;
    }

    .continue__CTA {
      padding: 0.8rem 1.5rem;
      border: 1px solid #e5e5e5;
      border-radius: 4rem;
      background: var(
        --linner-3,
        linear-gradient(243deg, #eae2ff 0%, rgba(187, 221, 252, 0.28) 100%)
      );
      color: var(--cykea-main, #181a49);
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 28rem) {
    .cert__content--container {
      min-width: 28.2rem;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 8rem;
    }

    .certificate__description {
      margin-bottom: 3rem;
      font-size: 0.9rem;
    }

    .downloadable__certificate-description {
      margin-bottom: 3rem;
      font-size: 0.9rem;
    }

    .cert__Ceo {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
    }

    .cert__date {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
    }

    .cert__number {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 4px;
      color: #989898;
    }
  }
  @media screen and (max-width: 26rem) {
    .cert__container {
      width: 351px;
      height: 336px;
    }

    .logo-name-and-title {
      display: flex;
      flex-direction: row;
      height: 0rem;
      align-items: center;
    }

    .desc-and-sign {
      height: 100%;
      margin-right: -3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .share__and__continue--CTA {
    }

    .sign__and__name {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .passed__content {
      width: 90%;
    }

    .cert_of_completion {
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 11px;
      color: #655d8a;
    }

    .cert__course--title {
      font-style: normal;
      font-weight: 600;
      font-size: 8.5px;
      line-height: 11px;
      color: #655d8a;
      margin-bottom: 3.8rem;
    }

    .cert__user--name {
      font-family: "Sora";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      color: #000000;
    }

    .cert__desc {
      font-style: normal;
      font-weight: 400;
      font-size: 4px;
      line-height: 158%;
      color: #4a5567;
    }

    .cert__date--text {
      font-size: 5px;
      font-style: normal;
      font-weight: 400;
    }

    .cert__barge-icon {
      width: 40px;
    }
    .signature {
      width: 6rem;
    }

    .date__ofissuance--span {
      display: block;
    }

    .cert__Ceo {
      font-size: 5px;
      font-style: normal;
      font-weight: 400;
    }

    .cert__date {
      font-style: normal;
      font-weight: 500;
      font-size: 7px;
    }

    .cert__number {
      font-style: normal;
      font-weight: 400;
      font-size: 3px;
      line-height: 4px;

      color: #989898;
    }

    .sign__and__date {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .cert__desc-and-barge {
      display: flex;
      height: 67%;
    }
  }
  @media screen and (max-width: 24rem) {
    .viewport__height {
      overflow-y: visible !important;
    }

    .question__number-and-close__icon--passed {
      height: 4rem;
    }
  }
}
