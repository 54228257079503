.hero__section-container {
  /* height: 100vh; */
  background: #f9f7fd;
}

.hero__content-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 6rem 4rem;
}

.hero__image-box {
  min-width: 30.5rem;
  max-width: 40rem;
  margin-bottom: 4rem;
}

.hero__img {
  width: 100%;
  height: auto;
}

.hero__text-box {
  max-width: 45rem;
}

.hero__main-text {
  font-family: Red Hat Display;
  font-size: 26px;
  font-weight: 700;
  line-height: 34.4px;
  text-align: center;
  margin-bottom: 2.5rem;
}

.hero__sub-text {
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.48px;
  text-align: center;
  margin-bottom: 4rem;
}

.hero__enroll-CTA {
  width: 200px;
  height: 60px;
  top: 464px;
  left: 84px;
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  line-height: 31.23px;
  background: #181a49;
  border-radius: 4rem;
  margin-bottom: 4rem;
}
.hero__CTAs {
  display: flex;
  justify-content: center;
}

.hero__start-date--span {
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 26.21px;
  text-align: left;
  display: flex;
  gap: 1.3rem;
  align-items: center;
}

.hero__start-date--span--desktop {
  display: none;
}

@media screen and (min-width: 62.5rem) {
  .hero__content-box {
    flex-direction: row-reverse;
    justify-content: center;
  }

  .hero__text-box {
    margin-left: 7.4rem;
    max-width: 72rem;
  }
  .hero__image-box {
    width: 40rem;
    margin-bottom: 0rem;
  }

  .hero__main-text {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 47.63px;
    text-align: left;
  }

  .hero__sub-text {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 39.31px;
    text-align: left;
  }

  .hero__CTAs {
    justify-content: flex-start;
    align-items: center;
    gap: 2.6rem;
  }

  .hero__enroll-CTA {
    margin-bottom: 0rem;
  }

  .hero__start-date--span--desktop {
    display: flex;
    gap: 3.5rem;
  }

  .hero__start-date {
    font-family: Red Hat Display;
    font-size: 2rem;
    font-weight: 700;
    line-height: 32.76px;
    text-align: left;
  }

  .hero__start-date--span {
    display: none;
  }
}
