.header__container {
  background-color: #ffffff;
  width: 100%;
  padding-top: 11rem;
  position: relative;
  overflow: hidden;
}

.mobile__header-bg {
  /* display: none; */
  /* background-image: url(../../assets/images/cy-header-mb-bg.png); */
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
}

.mb__no-img--bg {
  /* background-image: url(../../assets/images/cy-header-mb-no-img-bg.svg); */
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
}

.header__text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ethical__hacking-CTA {
  display: flex;
  border: 1.5px solid #e3d8ff;
  border-radius: 6rem;
  box-shadow: 0px 7px 30px 0px #f2e8ff66;
  padding: 0.5rem 2rem;
  align-items: center;
  margin-bottom: 2.8rem;
  gap: 1.2rem;
  cursor: pointer;
}

.ethical__hacking-text--box {
}

.ethical__siren {
}

.ethical__hacking-main--text {
  background: linear-gradient(
    269.7deg,
    #a17eff 9.07%,
    #9b75ff 36.74%,
    #4c9efe 70.16%,
    #57a3fd 106.17%
  );
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-family: "Urbanist" "sans-serif";
  font-size: 1.8rem;
  font-weight: 500;
}

.ethical__hacking-sub--text {
  font-size: 1.2rem;
  font-weight: 400;
  /* line-height: 21.17px; */
  text-align: left;
}

.header__image-container {
  background-color: #181a49;
}

.header__image {
  width: 100%;
  height: auto;
}

.ignite__icon-span {
  position: relative;
}

.ignite__icon {
  position: absolute;
  top: -15%;
  left: -18px;
  right: 0;
  bottom: 0;
  width: 29px;
  height: 27px;
}

.header__main--text {
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 131.77%;
  text-align: center;
  color: #181a49;
  margin-bottom: 2.2rem;
}

.header__sub-text {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 163.77%;
  text-align: center;
  color: #807d9e;
  margin-bottom: 4.4rem;
  width: 38rem;
}

.header__CTAs-span {
  display: flex;
  margin-bottom: 32rem;
  align-items: center;
  gap: 1.2rem;
}

.header__ethical-hacking--CTA {
  width: 15rem;
  height: 5rem;
  background: #ffffff;
  border-radius: 42px;
  border: 1.5px solid rgba(154, 204, 251, 0.72);
  font-size: 1rem;
  font-weight: 700;
  line-height: 31.23px;
  color: #181a49;
  position: relative;
}

.ethical__hacking-new--icon {
  position: absolute;
  top: -29%;
  left: 85%;
}

.enroll__now-CTA {
  width: 15rem;
  height: 5rem;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );

  border-radius: 42px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 31.23px;
  color: #181a49;
}

.header__guy-img {
  position: absolute;
  height: auto;
  width: 30rem;
  margin-right: -9rem;
  right: 0;
  bottom: 0;
  animation: smooth-zigzag-1 6s linear infinite;
}

.header__lady-img {
  position: absolute;
  height: auto;
  width: 30rem;
  margin-left: -11rem;
  bottom: 0;
  animation: smooth-zigzag-2 6s linear infinite;
}

@media screen and (min-width: 30.56rem) {
  /* .enroll__now-CTA {
    margin-bottom: 45rem;
  } */
}
@media screen and (min-width: 37.56rem) {
  .header__main--text {
    font-size: 5.9rem;
    /* width: 44rem; */
  }

  .header__sub-text {
    font-size: 2.1rem;
    /* width: 39rem; */
  }

  .header__image {
    width: 83%;
  }

  .header__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url(../../assets/images/cy-header-bg.png); */
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 4rem;
    width: 100%;
  }

  .no__img-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url(../../assets/images/cy-header-no-img-bg.svg); */
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 4rem;
    width: 100%;
  }

  .enroll__now-CTA {
    /* margin-bottom: 9rem; */
  }
}

@media screen and (min-width: 48rem) {
  .header__content-container {
    background-position: top;
  }

  .no__img-bg {
    background-position: top;
  }

  .header__text-container {
    /* align-items: flex-start; */
  }

  .header__main--text {
    /* text-align: left; */
    font-style: normal;
    font-weight: 700;
    font-size: 5rem;
    line-height: 131.77%;
    /* width: 36rem; */
    margin-bottom: 2.5rem;
  }

  .header__sub-text {
    /* text-align: left; */
    width: 44rem;
    margin-bottom: 3rem;
  }

  .header__image {
    width: 48%;
    margin-top: -2rem;
    /* background-color: red; */
  }

  .header__image {
    width: 48%;
    margin-top: -2rem;
    /* background-color: red; */
  }
}
@media screen and (min-width: 62rem) {
  .ethical__hacking-CTA {
    height: 9rem;
    padding: 1.5rem 5.4rem;
    gap: 2rem;
  }

  .ethical__hacking-main--text {
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 31.2px;
    text-align: left;
  }

  .ethical__hacking-sub--text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 21.17px;
    text-align: left;
  }

  .header__main--text {
    font-style: normal;
    font-weight: 800;
    font-size: 5.8rem;
    line-height: 131.77%;
  }

  .ignite__icon {
    height: 42px;
    width: 39px;
    left: -23px;
  }

  .header__image {
    width: 48%;
  }

  .header__guy-img {
    position: absolute;
    height: auto;
    width: 42rem;
    margin-right: -13rem;
    right: 0;
    top: 6rem;
    border-radius: 0px;
  }
  .header__lady-img {
    position: absolute;
    height: auto;
    width: 42rem;
    margin-left: -12rem;
    bottom: 6rem;
    border-radius: 0px;
  }
}

@media screen and (min-width: 75rem) {
  .header__content-container {
    justify-content: center;
    /* padding: 0 9.5rem; */
  }

  .no__img-bg {
    justify-content: center;
  }

  .header__main--text {
    font-style: normal;
    font-size: 6.5rem;
    line-height: 131.77%;
  }

  .header__sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 163.77%;
    margin-bottom: 4.8rem;
  }

  .header__CTAs-span {
    gap: 2rem;
  }

  .enroll__now-CTA {
    width: 229px;
    height: 70px;
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.6rem;
  }
  .header__ethical-hacking--CTA {
    width: 29.6rem;
    height: 7rem;
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .header__lady-img {
    bottom: 12rem;
  }
}

@media screen and (min-width: 90rem) {
  .header__container {
    padding-top: 4rem;
  }

  .header__content-container {
    padding-bottom: 6rem;
    padding-top: 10rem;
  }

  .no__img-bg {
    padding-bottom: 6rem;
    padding-top: 10rem;
  }

  .header__text-container {
  }

  .header__main--text {
    font-style: normal;
    font-size: 7.5rem;
    line-height: 131.77%;
  }

  .header__sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 163.77%;
    width: 55rem;
  }

  .ignite__icon {
    height: 5.8rem;
    width: 6.7rem;
    left: -3.9rem;
    top: -19%;
  }

  .header__guy-img {
    width: 46rem;
    margin-right: -13rem;
    right: 0;
    top: 6rem;
  }
  .header__lady-img {
    width: 46rem;
    margin-left: -12rem;
  }
}
/* @media screen and (min-width: 100rem) {
  .header__container {
    padding-top: 16rem;
  }

  .header__content-container {
    padding-bottom: 6rem;
    padding-top: 0;
  }

  .header__text-container {
    margin-right: 0;
  }

  .header__main--text {
 
    font-size: 9rem;
  }

  .header__sub-text {
    font-size: 3.4rem;
    width: 63rem;
  }

  .header__image {
    width: 40%;
  }

  .header__guy-img {
    width: 54rem;
    margin-right: -14rem;
    right: 0;
    top: 6rem;
  }

  .header__lady-img {
    width: 54rem;
    margin-left: -16rem;
   
  }
}
@media screen and (min-width: 125rem) {

  .header__container {
  }

  .header__content-container {
  }

  .header__text-container {
  }

  .header__main--text {
    font-style: normal;
    font-weight: 700;
    font-size: 11.5rem;
    line-height: 135%;
    margin-bottom: 2.8rem;
  }

  .header__sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 4.2rem;
    line-height: 163.77%;
    width: 77rem;
    margin-bottom: 9rem;
  }

  .header__image {
    margin-top: -4rem;
  }

  .enroll__now-CTA {
    padding: 4rem 6.7rem;
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 4rem;
    border-radius: 6.6rem;

  }
  .header__ethical-hacking--CTA {
    padding: 4rem 6.7rem;
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 4rem;
    border-radius: 6.6rem;
  }

  .ignite__icon {
    height: 9.8rem;
    width: 9.7rem;
    left: -5.9rem;
    top: -26%;
  }

  .header__guy-img {
    width: 76rem;
    margin-right: -20rem;
    top: 6rem;
  }

  .header__lady-img {
    width: 76rem;
    margin-left: -20rem;
    bottom: 11rem;
  }
} */

@keyframes smooth-zigzag-1 {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(10px, 10px);
  }
  50% {
    transform: translate(5px, 5px);
  }
  75% {
    transform: translate(10px, -5px);
  }
}

@keyframes smooth-zigzag-2 {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(-10px, -10px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  75% {
    transform: translate(-10px, 5px);
  }
}

@keyframes smooth-zigzag-3 {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(-7px, -7px);
  }
  50% {
    transform: translate(-14px, -14px);
  }
  75% {
    transform: translate(-7px, -7px);
  }
}
