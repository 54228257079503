.our__mission-container {
  display: flex;
  flex-direction: column;
  padding: 13rem 2rem 11rem 2rem;
  background-color: #f8fafc;
}

.our__mission-banner {
  border-radius: 15px;
  /* background: #edf0ff;  */
  /* min-height: 19.2rem; */
  /* margin-bottom: 3.5rem; */
  position: relative;
}

.our__mission-image {
  width: 100%;
}

.our__mission-banner--icon {
  position: absolute;
}

.mission__text-container {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
  margin-bottom: 3.5rem;
  padding: 3rem 4rem 7rem 4rem;
  width: 100%;
}

.our__mission-heading {
  color: #181a49;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 2rem;
}

.mission__text {
  color: var(--cykea-main-text, #181a49);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (min-width: 48rem) {
  .our__mission-container {
    padding: 11rem 2rem 11rem 4rem;
  }

  .our__mission-banner {
    width: 75%;
  }

  .mission__text-container {
    width: 75%;
  }

  .mission__text {
    font-size: 1.4rem;
  }
}
