.submitPrompt__container {
  border-radius: 15px;
  background: #edf0ff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitPrompt__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submit__prompt-icon {
  margin-bottom: 4rem;
}

.submit__prompt-text {
  color: var(--cykea-main, #181a49);
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 132.5%; /* 33.125px */
  margin-bottom: 5rem;
}

.try__again-CTA {
  color: var(--cykea-main, #181a49);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5.3rem;
  padding: 1rem 2.9rem;
  background-color: #fff;
  border: 1px solid rgba(154, 204, 251, 0.72);
  margin-left: 1.5rem;
}

.submit__quiz-CTA {
  background: var(
    --linerr-deeper,
    linear-gradient(243deg, #e6dcff 0%, rgba(154, 204, 251, 0.72) 100%)
  );
  color: var(--cykea-main, #181a49);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5.3rem;
  padding: 1rem 2.9rem;
}
