.faqs__container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20rem;
  padding-bottom: 8.5rem;
  padding-left: 12.8rem;
  padding-right: 12.8rem;
}

.faqs__heading {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 53px;
  /* identical to box height, or 88% */

  display: flex;
  align-items: center;

  color: #181a49;
  margin-bottom: 3.5rem;
  color: #222222;
}

.faqs__content-container {
  background-image: url(../../assets/images/faq-bg.png);
  background-size: cover;
  background-position: center;
  width: 70%;
  min-height: 572px;
  /* background: #ffffff; */
  /* background: cadetblue; */
  border: 1px solid #ffeaf2;
  box-shadow: 0px 4px 22px rgba(229, 227, 229, 0.1);
  border-radius: 31px;
  padding: 5rem 8.5rem 3.8rem 4.5rem;
}

@media screen and (max-width: 100rem) {
  .faqs__container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 17rem;
    padding-bottom: 8.5rem;
    padding-left: 12.8rem;
    padding-right: 12.8rem;
  }

  .faqs__heading {
    font-style: normal;
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 33px;
    margin-bottom: 3.5rem;
    color: #222222;
  }

  .faqs__content-container {
    width: 1051px;
    min-height: 572px;
    /* background: cadetblue; */
    border: 1px solid #ffeaf2;
    box-shadow: 0px 4px 22px rgba(229, 227, 229, 0.1);
    /* border-radius: 15px; */
    padding: 5rem 8.5rem 3.8rem 4.5rem;
  }
}

@media screen and (max-width: 70rem) {
  .faqs__container {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }

  .faqs__content-container {
    width: 100%;

    padding: 5rem 4.5rem 3.8rem 4.5rem;
  }
}
@media screen and (max-width: 48rem) {
  .faqs__container {
    padding-top: 8rem;
  }

  .faqs__container {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .faqs__content-container {
    width: 100%;
    padding: 5rem 0rem 3.8rem 2rem;
  }

  .faqs__heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
  }
}
