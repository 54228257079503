.alert__container--div {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10000000;
}

@keyframes alertMove {
  0% {
    top: 0px;
    opacity: 0;
  }
  /* 25% {
    top: 20px;
    opacity: 0.3;
  } */
  /* 50% {
    top: 40px;
    opacity: 0.6;
  } */
  /* 75% {
    top: 150px;
    opacity: 0.9;
  } */
  /* 100% {
    top: 60px;
    opacity: 1;
  } */
}

.success__alert__container {
  width: 374px;
  height: 40px;
  /* left: 30%; */
  top: 40px;
  background: #dfffe4;
  border: 0.4px solid #f2f5f9;
  border-radius: 5px;
  position: absolute;
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  color: #014d0e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding: 0 1.5rem;
  animation: alertMove 1s;
}

.failed__alert__container {
  width: 374px;
  height: 40px;
  /* left: 56rem; */
  top: 40px;
  background: #fef9fa;
  border: 0.4px solid #f2f5f9;
  border-radius: 5px;
  position: absolute;
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  color: #760707;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding: 0 1.5rem;
  /* transform: translateY(-2rem); */
  animation: alertMove 1s;
}

@media screen and (max-width: 26rem) {
  .success__alert__container {
    width: 297px;
    height: 38px;
    background: #dfffe4;
    border: 0.4px solid #f2f5f9;
    border-radius: 5px;
  }

  .failed__alert__container {
    width: 297px;
    height: 38px;
    background: #fef9fa;
    border: 0.4px solid #f2f5f9;
    border-radius: 5px;
  }
}
