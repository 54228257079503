.notification__alert-container {
  /* background-color: red; */
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 20000;
  top: -2rem;
}

.notification__text-span {
  color: #181a49;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 143.5%;
  padding: 1.4rem 1.7rem;
  border: 1px solid #cc2442;
  border-radius: 10rem;
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.close__notification-icon {
  margin-left: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 48rem) {
  .notification__position-div {
    position: unset;
  }

  .notification__alert-container {
    position: fixed;
    top: 2rem;
    width: unset;
    right: 0;
    margin-right: 1rem;
  }
}
