.not__found-container {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 5.6rem;
}

.not__found-content {
  /* width: 50%; */
  /* background-color: red; */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not__found-text {
  color: var(--cykea-main, #181a49);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10rem;
}

.something__wrong {
  color: #6b6790;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 5.3rem 0;
}

.back-to-homepage__CTA {
  /* min-height: 7rem;
  width: 27.7rem; */
  color: #181a49;
  background-color: #fff;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 1.4rem 4.8rem;
  border: 1px solid rgba(154, 204, 251, 0.72);
  border-radius: 5rem;
}

@media screen and (max-width: 48rem) {
  .not__found-text {
    font-weight: 700;
    font-size: 37px;
    line-height: 47px;
  }

  .something__wrong {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin: 5.3rem 0;
  }
}
