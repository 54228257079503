.sidebar__container {
  width: 20%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #f2f5f9;
  position: relative;
  padding: 0 2rem;
}

.dashboard__sideBar-logo {
  height: 30px;
  width: 58.6363639831543px;
}

.logout__prompt--container {
  position: absolute;
  /* top: 0; */
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 18.8rem;
  height: fit-content;
  z-index: 105;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(194, 202, 209, 0.5);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 1.4rem;
  cursor: auto;
}

.logout__prompt--content {
  width: 460px;
  height: 333px;
  background: #ffffff;
  background-color: aquamarine;
  box-shadow: 0px 4px 40px rgba(221, 221, 221, 0.2);
  border-radius: 15px;
}

.cancel__logout--icon {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 2rem 0 0;
}

.logout__prompt--text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #171531;
  margin: 0.5rem 0;
}

.logout__prompt-sub--text {
  font-weight: 400;
  font-size: 10px;
  line-height: 141.93%;
  text-align: center;
  color: #807d9e;
  margin-bottom: 3rem;
}

.logout__peace-icon {
  margin-bottom: 1.4rem;
}

.logout__icon-and-CTAs {
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80%;
  border-radius: 0 0 15px 15px;
}

.yes__logout {
  padding: 1.1rem 2.2rem;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  border-radius: 20px;
  margin-right: 0.5rem;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #181a49;
}

.cancel__logout {
  padding: 1.1rem 2.2rem;
  background: #ffffff;
  border-radius: 20px;
  margin-left: 0.5rem;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  border: 1px solid rgba(187, 221, 252, 0.28);
  color: #181a49;
}

.auth__company--logo {
  padding: 1.1rem 2.3rem;
  width: 14.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  box-shadow: 0px 2px 22px rgba(202, 190, 214, 0.2);
  border-radius: 25px;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #181a49;
  margin-bottom: 3.5rem;
  cursor: pointer;
}

.our__mission-icon {
  margin-left: 5px;
}

.sidebar__content--container {
  /* background-color: blueviolet; */
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.side__nav--links {
  /* margin-top: 2rem; */
}

.sidebar__inner--content {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  height: 75%;
}

.dashboard__fezzant--logo-span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 58px;
  border-bottom: 1px solid #f2f5f9;
  width: 100%;
  /* background-color: gold; */
  justify-content: center;
  margin-bottom: 3rem;
}

/* .dashboard__fezzant--logo-span {
    height: 59px;
    display: flex;
  } */

.link__and__icon {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  cursor: pointer;
  /* background-color: bisque; */
}

.notColored {
  display: none;
}

.link__and__icon .notColored {
  display: flex;
  align-items: center;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.active {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  cursor: pointer;
  /* background: #efdfe5; */
  border-radius: 5px;
}

.mobile__nav {
  display: none;
}

.mobile__nav-closed {
  display: none;
}

.colored {
  display: none;
}

.active .colored {
  display: flex;
}

.active .link {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #181a49;
}

.active > svg {
  width: 2rem;
  margin-right: 1.7rem;
}

.link {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 121.8%;
  color: #6b6790;
  margin-left: 2rem;
}

.link__and__icon:not(:last-child) {
  margin-bottom: 2.5rem;
}
.active:not(:last-child) {
  margin-bottom: 2.5rem;
}
.link__and__icon > svg {
  width: 2rem;
  margin-right: 1.7rem;
}

.logout__prompts--CTAs {
  display: flex;
}

.logout__CTA {
  /* margin-bottom: 7rem; */
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgba(202, 190, 214, 0.2);
  border-radius: 25px;
  width: fit-content;
  padding: 1.2rem 2.5rem;
  cursor: pointer;
  position: relative;
}

.power__icon {
  margin-right: 7.7px;
}

.sidebar__logout-text {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 121.8%;
  color: #a90f0f;
}

.nav-links {
  display: flex;
  width: 100%;
}

.links {
  display: none;
  width: 100%;
}

@media screen and (max-width: 90rem) /*768/16*/ {
  .dashboard__sideBar-logo {
    height: 30px;
    width: 58.6363639831543px;
  }
}
@media screen and (max-width: 48rem) /*768/16*/ {
  .auth__company--logo {
    width: fit-content;
  }
  .logout__prompt--container {
    display: flex;
  }

  .logout__prompt--content {
    width: 90%;
  }

  .sidebar__container {
    width: 100%;
    /* background-color: cornflowerblue; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f2f5f9;
  }

  .logout__CTA {
    margin-bottom: 0;
    margin-top: 7rem;
  }

  .hidden {
    display: none;
  }

  /* .active {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    cursor: pointer;
    background: transparent;
    border-radius: 5px;
    color: red;
  } */

  /* .link__and__icon > svg {
    fill: #858585;
  } */

  /* .side__nav--links {
    margin-top: 6rem;
  } */

  .mobile__sideBar-logo {
    width: 5rem;
    height: auto;
  }

  .mobile__nav {
    /* background-color: red; */
    position: fixed;
    left: 0;
    top: 0;
    padding: 2rem;
    /* bottom: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 233px;
    height: 100vh;
    background-color: #ffffff;
    border: none;
    opacity: 1;
    z-index: 100;
    /* background: transparent; */
    box-sizing: border-box;
    transition: 0.2s ease-in-out;
  }
  .mobile__nav-closed {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 233px;

    /* background-color: gold; */
    border: none;
    z-index: 100;
    transform: translate(-100%);
    -webkit-transition-duration: 4s;
    transition-duration: 400ms;
  }

  .mobile__nav-overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  }

  .nav-links {
    width: 100%;
    background-color: #fff;
    /* background-color: green; */
    height: 100%;
    /* border: 0.5px solid #f2f5f9; */

    display: flex;
    background: transparent;
    /* box-shadow: 0px 2px 22px rgba(185, 185, 185, 0.2); */
    /* align-items: center; */
    flex-direction: column;
  }

  .mobile__nav-logo-and-close--span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(154, 204, 251, 0.72);
    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }
  /* .link__and__icon:not(:last-child) {
    margin-bottom: 0;
  } */
  /* .active:not(:last-child) {
    margin-bottom: 0;
  } */

  .links {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    background-color: #ffffff;
  }

  .logout__mobile--CTA {
    border-radius: 1px solid grey;
    width: 100%;
  }
}
