.single__course-container {
  background: #f8fafc;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  width: 100%;
  padding: 9rem 6rem 11rem 4.1rem;
}

.hide__mobile-view {
  display: none;
}

.single__course-content--container {
  /* background-color: gold; */
  /* height: 100vh; */
  display: flex;
  width: 100%;
}

.single__course-video-and-overview--container {
  height: 100%;
  /* background-color: rebeccapurple; */
  width: 70%;
  margin-right: 2.6rem;
}

.single__course-content--div {
  height: 742px;
  width: 317px;
  border-radius: 1rem;
  /* background: #FFFFFF; */
  border: 1px solid #f2f5f9;
}

.single__course-heading--span {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.single__course-heading--mobile {
  display: none;
}

.single__course-heading--text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #949699;
  margin-right: 1.8rem;
}

.single__course-course--title {
  color: #171531;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.course__detail-CTA {
  padding: 0.8rem 1.9rem;
  font-size: 1.2rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4rem;
  margin-left: 1rem;
}

.course__content-active--tab {
  background: #181a49;
  color: #fff;
  padding: 0.8rem 1.9rem;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4rem;
  margin-left: 1rem;
}

.course__content-inactive--tab {
  background-color: #fff;
  color: #181a49;
  border: 1px solid rgba(154, 204, 251, 0.72);
  padding: 0.8rem 1.9rem;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4rem;
  margin-left: 1rem;
}

.share__options-and-CTA {
  position: relative;
}

.download__available-cert--CTA {
  color: #181a49;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.8rem 2rem;
  margin-left: 1rem;
  border-radius: 4rem;
  border: 1px solid var(--Linner_3, #eae2ff);
  background: var(
    --cykea-linear-main,
    linear-gradient(266deg, #e6dcff 35.33%, rgba(154, 204, 251, 0.72) 121.98%)
  );
}

.course__share-options--span {
  position: absolute;
  left: -8.1rem;
  top: 3.8rem;
}

.share__options-container {
  position: absolute;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 2px 22px 0px rgba(217, 217, 217, 0.2);
  width: 13.1rem;
  top: 3.7rem;
  right: 0;
  padding: 2.5rem 2rem 0 2rem;
}

.share__option {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  cursor: pointer;
  color: #6b6790;
}

.share__option-icon {
  margin-right: 1rem;
}

.course__share-CTA {
  border: 1px solid #fff;
  background: #c9f0d9;
  color: #0d4423;
}

.course__text-details--container {
  /* background-color: red; */
  max-width: 60rem;
  padding: 2rem 2.5rem;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
}

/* Main Container */

.video__js-container {
  border-radius: 20rem;
  position: relative;
}

.video__player--container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-radius: 15px;
  margin-bottom: 4rem;
  /* border: 1px solid #f2f5f9;
  border-top: none;
  border-bottom: none !important; */
  box-shadow: 0px 2px 22px rgb(219 218 218 / 31%);
}

/* Video */

.video {
  width: 100%;
  height: 100%;
  background-color: black;
  /* object-fit: cover; */
  border-radius: 15px;
  border-top: none;
  /* border: 1px solid #e4e4e4;
  border-bottom: none; */
}

/* Controls */

.controlsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: black;
  background-color: transparent;
  height: 100%;
  top: 0;
  padding: 0 40px;
  z-index: 5;
  border-radius: 1.5rem;
  position: absolute;
  opacity: 0;
  border-bottom: none;
  /* border: 1px solid #f2f5f9; */
}

.controlsContainer:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
  background-image: linear-gradient(to bottom, black, rgba(78, 78, 78, 0.249));
  border-radius: 1.5rem;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: none;
}

.controlsIcon {
  cursor: pointer;
}

.controlsIcon--small {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

/* The time controls section */

.speaker__icon-and-control {
  position: relative;
  display: flex;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.video__control-bar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  left: 1.5%;
  right: 0;
  bottom: 2rem;
  background: rgba(174, 174, 174, 0.14);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 8;
  border-radius: 7px;
  width: 97%;
  height: 3.7rem;
}

.time_progressbarContainer {
  background-color: gray;
  border-radius: 15px;
  width: 75%;
  height: 5px;
  z-index: 30;
  position: relative;
  margin: 0 20px;
  cursor: pointer;
}

.time_progressBar {
  border-radius: 15px;
  background: #efdfe5;
  /* transition: 2s ease-in-out; */
  height: 100%;
  position: relative;
  transition: width 0.2s linear;
}

.progressBar__head {
  position: absolute;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
  background-color: #ffffff;
  top: -5px;
  right: -5px;
  bottom: 0;
  pointer-events: none;
  /* left: 100%; */
  /* z-index: -10; */
}

.controlsTime {
  color: white;
  width: fit-content;
  display: flex;
  align-items: center;
}

.speaker-and-fullscreen--icons {
  width: 7rem;
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}

input[type="range"] {
  /* position: absolute; */
  -webkit-appearance: none;
  transform: rotate(270deg);
  cursor: pointer;
  /* height: 4rem; */
  width: 9rem;
  height: 0;
  top: -2.5rem;
  left: -18px;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  /* background: red; */
}

/* input[type="range"] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
} */
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  /* width: 100%; */
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #ffffff;
  border-radius: 5px;
  border: 0px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #aeaeb5;

  background: #ffffff;

  height: 14px;
  width: 14px;
  border-radius: 25px;

  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  margin-top: -4.5px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  /* background: #2497e3; */
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  /* background: red; */
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  /* border: 1px solid red; */
  height: 18px;
  width: 18px;
  border-radius: 25px;
  /* background: red; */
  cursor: pointer;
}
input[type="range"]::-ms-track {
  /* background-color: red; */
  /* width: 100%; */
  /* height: 5px; */
  cursor: pointer;
  /* animate: 0.2s; */
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  /* background: green; */
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-fill-upper {
  /* background: red; */
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #aeaeb5;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffffff;
  cursor: pointer;
}

.course__overview {
  /* background-color: orangered; */
  padding-top: 2.5rem;
}

.course__share-options--span {
}

.course__content-CTA--mobile-active {
  display: none;
}

.course__content-CTA--mobile-inactive {
  display: none;
}

.course__overview-heading-and-dropDown--icon {
  /* background-color: #2497e3; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.course__overview-heading {
  font-style: normal;
  font-weight: 506;
  font-size: 18px;
  color: #171531;
}
.desc {
  color: #181a49;
  font-size: 1.4rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
}

.desc__text {
  color: #807d9e;
  font-style: normal;
  font-weight: 400;
  line-height: 141.935%;
  font-size: clamp(1.2rem, 1.4vw, 1.4rem);
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(154, 204, 251, 0.72);
}

.instructor {
  color: #181a49;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
}

.instructor__details {
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  width: 100%;
  margin-bottom: 2.5rem;
}

.instructor__img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 1.2rem;
}

.instructor__name {
  color: #181a49;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 141.935%;

  margin-right: 1rem;
  margin-bottom: 1rem;
}

.instrctr__title {
  color: #807d9e;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 141.935%;
  margin-left: 1rem;
}

.instructor__linkedin {
  margin-right: 1.5rem;
}

.details {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4a5567;
}

.course__details-span {
  display: flex;
  align-items: center;
}

.duration {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #949699;
}

.learning__checks {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 141.93%;
  color: #949699;
}

.modules {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #949699;
}

.course__content-heading-and-closeCTA {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 1.5rem;
  /* background-color: #2497e3; */
}

.course__content-heading {
  font-family: "Sora";
  font-style: normal;
  font-weight: 506;
  font-size: 14px;
  line-height: 18px;
  color: #171531;
}

/*Custom Scrollbar*/

/* width */
::-webkit-scrollbar {
  /* width: 1rem; */
  width: 2.5px;

  /* margin: 2rem 0; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  opacity: 0.4;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c6cedd;
  border-radius: 4px;
}

.course__content-accordion--container {
  /* background-color: tomato; */
  height: 100%;
  overflow-y: scroll;
  background: transparent;
  box-shadow: 0px 8px 30px 0px rgba(227, 233, 239, 0.5);
}

.video__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.89) 0%, #383838 100%); */
  background: linear-gradient(180deg, rgb(0 0 0 / 84%) 0%, #383838 100%);
  border-radius: 15px;
  z-index: 10;
}

.video__overlay-content {
  display: flex;
  /* background-color: #2497e3; */
  /* width: 65%; */
  /* height: 55%; */
}

.video__overlay-text--span {
  /* background-color: #2497e3; */
  /* height: 30%; */
  width: fit-content;
  color: #efdfe5;
  margin-left: 4.3rem;
}

.next__content-type--text {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.2rem, 1.6vw, 1.6rem);
  color: #bfbfbf;
  margin-bottom: 1.8rem;
}

.next__content-title {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(1.5rem, 2vw, 2rem);
  color: #ffffff;
  margin-bottom: 1.8rem;
}

.next__content-duration {
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.3rem, 1.8vw, 1.8rem);
  color: #bfbfbf;
  margin-bottom: 1.8rem;
}

.next__content-start--CTA {
  color: #181a49;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #fff;
  border-radius: 4rem;
  padding: 0.8rem 4.3rem;
}

.loader-and-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.loader__text {
  text-align: center;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #171531;
  margin-top: 2rem;
}
@media screen and (max-width: 64rem) {
  .single__course-video-and-overview--container {
    margin-right: 1.2rem;
  }
  .single__course-container {
    padding-right: 2rem;
  }
}
@media screen and (max-width: 62rem) {
  .single__course-container {
    padding: 9rem 2rem 11rem 2rem;
  }
  .single__course-video-and-overview--container {
    width: 100%;
  }

  .duration {
    font-size: 1.2rem;
  }

  .learning__checks {
    font-size: 1.2rem;
  }

  .modules {
    font-size: 1.2rem;
  }

  .single__course-content--container {
    display: block;
  }
  .hide__mobile-view {
    display: block;
  }

  .single__course-content--div {
    display: none;
  }

  .course__content-CTA--mobile-active {
    display: initial;
    background: #181a49;
    color: #fff;
    padding: 0.8rem 1.9rem;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 4rem;
    margin-left: 1rem;
  }

  .course__content-CTA--mobile-inactive {
    display: initial;
    background-color: #fff;
    color: #181a49;
    border: 1px solid rgba(154, 204, 251, 0.72);
    padding: 0.8rem 1.9rem;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 4rem;
    margin-left: 1rem;
  }

  .hide__course-content--switch {
    display: none;
  }
}
@media screen and (max-width: 60rem) {
}
@media screen and (max-width: 56rem) {
}
@media screen and (max-width: 48rem) {
  .certificate__available-style {
    padding-top: 15rem;
  }

  .single__course-heading--span {
    display: none;
  }

  .single__course-heading--mobile {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .video {
    height: auto; /*to ensure the "object-fit" property scales properly with the height of the video*/
  }

  .course__overview-and-content--switch {
    /* background-color: #2497e3; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 3rem;
  }

  .course__overview-heading-and-dropDown--icon {
    display: block;
  }

  .single__course-course--title {
    margin-bottom: 1.5rem;
  }

  .open__contents--CTA {
    display: none;
  }

  .active__switch {
    font-family: "Sora";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #a734dd;
  }

  .unactive__switch {
    font-family: "Sora";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #949699;
    cursor: pointer;
  }

  .download__available-cert--CTA {
    display: none;
  }

  /* .mobile__course--overview {
    display: none;
  } */
}

@media screen and (max-width: 28rem) {
  .single__course-heading--mobile {
    width: 75%;
  }

  .next__content-start--CTA {
    padding: 0.8rem 1.3rem;
  }

  .course__content-active--tab {
    font-size: 1rem;
    padding: 1rem 1.7rem;
  }

  .course__content-inactive--tab {
    font-size: 1rem;
    padding: 1rem 1.7rem;
  }

  .course__content-CTA--mobile-active {
    font-size: 1rem;
    padding: 1rem 1.7rem;
  }

  .course__content-CTA--mobile-inactive {
    font-size: 1rem;
    padding: 1rem 1.7rem;
  }

  .course__share-CTA {
    font-size: 1rem;
    padding: 1rem 1.7rem;
  }
}

@media screen and (max-width: 22.8rem) {
  .course__share-CTA {
    margin-top: 1.5rem;
    margin-left: 0rem;
  }

  .share__options-container {
    top: 5.7rem;
    left: 0rem;
    right: 0;
  }
}
