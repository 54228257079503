.duration-and-start--CTA {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.start__practical-questions--CTA {
  color: #181a49;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 40px;
  background: var(
    --linner-3,
    linear-gradient(243deg, #eae2ff 0%, rgba(187, 221, 252, 0.28) 100%)
  );

  padding: 0.7rem 1.5rem;
}

.video__player-duration-and-status {
  align-items: flex-start !important;
  flex-direction: column;
}

.video__content-check-and-text {
  align-items: flex-start !important;
}
