.terms__content-container {
  /* background-color: aqua; */
  height: auto;
  padding: 25rem 5rem 3rem 5rem;
}

.term__of-use--heading {
  margin-bottom: 5rem;
  /* font-family: "Sora"; */
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  color: #222222;
}

.legals__body--container {
  text-align: left;
  width: 100%;
  background-color: red;
}

.legals__body {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 41px;
  color: #171531;
  padding: 0rem 9rem 14rem 9rem;
}

@media screen and (max-width: 100rem) {
  .terms__content-container {
    padding-top: 15rem;
  }
}

@media screen and (max-width: 48rem) {
  .legals__body {
    /* font-family: "Mulish"; */
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    color: #171531;
    padding: 0 2.2rem;
  }

  .term__of-use--heading {
    margin-bottom: 5rem;
    /* font-family: "Sora"; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: left;
    margin-left: 2.2rem;
    color: #171531;
  }
}
