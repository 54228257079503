.ethical__section-two--container {
  background: #f9f7fd;

  padding: 6rem 0rem;
}

.ethical__section-two--content {
  padding: 0 2.5rem;
}

.section__two-main--box {
  box-shadow: 0px 8px 30px 0px #e3e9ef80;
  border-radius: 3rem;
  background-color: #ffffff;
}

.section__two-title--box {
  padding: 2rem 1.5rem;
  border-bottom: 2px solid #eae2ff;
}

.course__overview-main--text {
  font-size: 22px;
  font-weight: 700;
  line-height: 29.11px;
  text-align: left;
  margin-bottom: 1rem;
}

.course__overview-sub--text {
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: left;
  color: #6b6790;
}

.section__two-course--overview--box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.section__two-course--content--detail {
  border-bottom: 2px solid #eae2ff;
  padding: 2.5rem 1.5rem;
}

.course__time-period {
  color: #e568f1;
  font-size: 20px;
  font-weight: 700;
  line-height: 26.46px;
  margin-bottom: 1.2rem;
}

.course__period-content {
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: left;
  color: #181a49;
}

.course__sprint-content--box {
  padding: 2.6rem 1.5rem;
}

.course__spring-heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 26.46px;
  text-align: left;
  margin-bottom: 3rem;
}

.listed__sprint-item {
  display: flex;
  gap: 1.3rem;
  align-items: center;
}
.listed__sprint-item:not(:last-child) {
  margin-bottom: 2.5rem;
}

.sprint__content-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.17px;
  text-align: left;
}

.ethical__list-icon {
  height: 1.6rem;
  width: 1.6rem;
}

@media screen and (min-width: 62.5rem) {
  .ethical__section-two--container {
    padding-top: 12rem;
  }
  .ethical__section-two--content {
    display: flex;
    justify-content: center;
  }
  .section__two-main--box {
    max-width: 124rem;
  }

  .section__two-title--box {
    padding: 5rem 4rem;
  }

  .course__overview-main--text {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 47.63px;
    margin-bottom: 2rem;
  }

  .course__overview-sub--text {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 38.4px;
  }

  .section__two-course--content--detail {
    display: flex;
    gap: 10rem;
    align-items: center;
    padding: 5rem 4rem;
  }
  .course__time-period {
    margin-bottom: 0;
    font-size: 3rem;
    font-weight: 700;
    line-height: 39.69px;
    min-width: 13rem;
  }

  .course__period-content {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 38.4px;
    text-align: left;
  }

  .course__sprint-content--box {
    padding: 5rem 4rem;
  }

  .course__spring-heading {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 47.63px;
    text-align: left;
    margin-bottom: 3rem;
  }

  .sprint__content-text {
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 34.4px;
    text-align: left;
  }

  .ethical__list-icon {
    height: 2rem;
    width: 2rem;
  }

  .listed__sprint-item:not(:last-child) {
    margin-bottom: 4rem;
  }
}
