.ethical__faq-container {
  background: #f9f7fd;
  padding: 10rem;
}

.ethical__faq-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 2rem;
}

.ethical__faq-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.75px;
  margin-bottom: 3rem;
}

.faq__enroll-CTA {
}

.ethical__accordion-container {
  /* background-color: cadetblue; */
  padding: 5.3rem 1.8rem;
  border: 0.3px solid #d2e9fe4d;
  border-radius: 3rem;
  color: #ffffff;
  min-width: 34rem;
  background-color: #ffffff;
}

@media screen and (min-width: 48rem) {
  .ethical__accordion-container {
    width: 60rem;
  }
}
@media screen and (min-width: 62.5rem) {
  .ethical__accordion-container {
    width: 90rem;
  }

  .ethical__faq-heading {
    font-size: 36px;
    font-weight: 700;
    line-height: 47.63px;
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 90rem) {
  .ethical__accordion-container {
    width: 106rem;
    padding: 7rem;
  }
}
