.skills__path-container {
  background-color: #ffffff;
  padding: 11rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.skills__path-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 173.5%;
  text-align: center;
  color: #181a49;
}

.main__skill-container {
  border: 1px solid #f5f0f0;
  box-shadow: 5px 14px 40px rgba(200, 200, 200, 0.2);
  border-radius: 30px;
  padding: 2rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 6rem;
}

.main__skill-icon {
  margin-bottom: 3rem;
  flex-grow: 1;
}

.main__skill-text--box {
  flex-grow: 1;
}

.main__skill-title {
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 131.77%;
  color: #181a49;
  margin-bottom: 3.5rem;
}

.course__outline-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 131.77%;
  color: #807d9e;
  margin-bottom: 2rem;
}

.lists__span {
  margin-bottom: 3rem;
}

.main__skill-path--list {
}

.main__skill-item {
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 16px;
  color: #807d9e;
  margin-bottom: 2.3rem;
}

.list__icon {
  height: 1rem;
  width: 1.4rem;
  margin-right: 7px;
}

.skills__path-CTA--span {
  display: flex;
  justify-content: center;
}

.skills__path-start--CTA {
  padding: 1.4rem 3rem;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  border-radius: 75px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 173.5%;
  color: #181a49;
}

.other__skills-container {
  display: flex;
  align-items: center;
  /* background-color: black; */
  flex-direction: column;
  width: 100%;
}
.skill__card {
  padding: 2rem;
  border-radius: 30px;
  width: 34rem;
  background: #ffffff;
  border: 1px solid #f5f0f0;
  box-shadow: 5px 14px 40px rgba(200, 200, 200, 0.2);
  margin-bottom: 3rem;
  /* flex-grow: 1; */
}

.skill__details {
  height: 427px;
  /* width: 340px; */
  border-radius: 30px;
}

.skill__image-box {
  /* background-image: url(../../assets/icons/skill-card-icon.svg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50%;
  border-radius: 3rem 3rem 0 0;
  /* width: 30rem; */
  /* height: auto; */
}

.skill__text-details--box {
  height: 50%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0rem;
}

.skill__title-text {
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  color: #181a49;
  /* margin-bottom: 4.5rem; */
}

.skill__availability-tag {
  background: linear-gradient(
    266.17deg,
    #eae2ff 35.33%,
    rgba(187, 221, 252, 0.28) 121.98%
  );
  border-radius: 4.2rem;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 1.5rem 6rem;
  color: #181a496e;
}

@media screen and (min-width: 37.56rem) {
  .main__skill-container {
    width: 80%;
  }

  .main__skill-item {
    font-size: 1.5rem;
  }

  .list__icon {
    margin-right: 9px;
  }
}

/* @media screen and (min-width: 44.5rem) {
} */
@media screen and (min-width: 48rem) {
  .main__skill-container {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }

  .main__skill-icon {
    width: 36%;
    margin-right: 2.5rem;
  }

  .lists__span {
    display: flex;
    margin-bottom: 1rem;
  }

  .first__list {
    margin-right: 2rem;
  }

  .main__skill-title {
    margin-bottom: 1.5rem;
  }

  .main__skill-item {
    font-size: 1.2rem;
    align-items: center;
  }

  .list__icon {
    margin-right: 7px;
  }

  .skills__path-CTA--span {
    justify-content: flex-start;
  }

  .skills__path-start--CTA {
    padding: 1.4rem 2rem;
    font-size: 1.5rem;
  }

  .other__skills-container {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .skill__card {
    margin-right: 2rem;
  }
}
@media screen and (min-width: 62rem) {
  .skills__path-container {
    padding: 11rem 2rem;
  }

  .main__skill-container {
    /* width: fit-content; */
    /* padding-bottom: 0; */
    margin-bottom: 7rem;
  }

  .skills__path-heading {
    margin-bottom: 5rem;
    font-weight: 700;
    font-size: 3.2rem;
  }

  .main__skill-icon {
    width: 40rem;
    margin-right: 3rem;
    /* height: 426px; */
  }

  .main__skill-title {
    font-style: normal;
    font-weight: 800;
    font-size: 2.8rem;
    line-height: 131.77%;
  }

  .lists__span {
    margin-bottom: 2.1rem;
  }

  .first__list {
    margin-right: 3rem;
  }

  .main__skill-item {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 19px;
  }

  .list__icon {
    margin-right: 1.3rem;
    height: 1rem;
    width: 1.5rem;
  }

  .skill__card {
    flex-grow: 0;
    margin-right: 3.5rem;
    width: 29rem;
  }
  .other__skills-container {
    justify-content: flex-start;
    /* padding: 0 0 0 2rem; */
  }
}

@media screen and (min-width: 75rem) {
  .skills__path-container {
    padding: 11rem 4rem;
  }

  .main__skill-container {
    width: 100%;
    padding: 3rem 2rem 0rem 3rem;
    margin-bottom: 10rem;
  }

  .main__skill-icon {
    width: 537px;
    /* height: 426px; */
  }

  .main__skill-title {
    font-style: normal;
    font-weight: 800;
    font-size: 3rem;
    line-height: 131.77%;
    margin-bottom: 3.5rem;
  }

  .lists__span {
    margin-bottom: 2.1rem;
  }

  .first__list {
    margin-right: 3rem;
  }

  .main__skill-item {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 19px;
  }

  .list__icon {
    margin-right: 1.3rem;
  }

  .skills__path-start--CTA {
    padding: 2rem 5rem;
    font-weight: 800;
    font-size: 2rem;
  }

  .skill__card {
    margin-right: 3.5rem;
    width: 33rem;
  }
}

@media screen and (min-width: 90rem) {
  .skills__path-container {
    padding: 11rem 16rem;
  }

  .main__skill-container {
    width: 120rem;
    padding: 3rem 8rem 0rem 3rem;
  }

  .other__skills-container {
    width: 120rem;
    padding: 0;
  }

  .first__list {
    margin-right: 5rem;
  }

  .skill__card {
    width: 37rem;
    margin-right: 3rem;
  }

  .skill__details {
    height: 469px;
  }

  .skill__title-text {
    font-weight: 800;
    font-size: 2rem;
  }

  .skill__availability-tag {
    font-weight: 800;
    font-size: 2rem;
    padding: 2rem 8rem;
  }

  .skill__card:not(:last-child) {
  }
}
@media screen and (min-width: 100rem) /*2000 / 16*/ {
  .skills__path-container {
    padding: 11rem 3rem;
  }
}

@media screen and (min-width: 125rem) /*2000 / 16*/ {
  .other__skills-container {
    width: 192rem;
  }
}
@media screen and (min-width: 125rem) /*2000 / 16*/ {
  .skills__path-container {
    padding-top: 16rem;
  }

  .main__skill-container {
    padding: 4.5rem 12rem 0rem 4.5rem;
    width: 192rem;
    margin-bottom: 15rem;
  }

  .main__skill-icon {
    width: 89rem;
    margin-right: 8rem;
  }

  .skills__path-heading {
    font-weight: 700;
    font-size: 6rem;
    line-height: 53px;
    margin-bottom: 9rem;
  }

  .main__skill-title {
    font-weight: 800;
    font-size: 45px;
    line-height: 131.77%;
    margin-bottom: 7rem;
  }

  .course__outline-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 131.77%;
    margin-bottom: 5rem;
  }

  .lists__span {
    margin-bottom: 7rem;
  }

  .first__list {
    margin-right: 8rem;
  }

  .main__skill-item {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 3.5rem;
  }

  .skills__path-start--CTA {
    padding: 4rem 8rem;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    border-radius: 6.6rem;
  }

  .list__icon {
    height: 13.75px;
    width: 20px;
    margin-right: 3rem;
  }

  /* .main__skill-container {
    width: fit-content;
  } */

  .other__skills-container {
    width: 192rem;
  }

  .skill__details {
    height: 802px;
    border-radius: 30px;
  }

  .skill__card {
    width: 59rem;
    margin-right: 5rem;
    margin-bottom: 5rem;
  }

  .skill__text-details--box {
    padding: 5rem 0;
  }

  .skill__title-text {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
  }

  .skill__availability-tag {
    border-radius: 66px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    padding: 4re 8rem;
  }
}
