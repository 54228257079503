/* .hb__form {
  background: rgba(174, 174, 174, 0.14);
  backdrop-filter: blur(0.7rem);
  -webkit-backdrop-filter: blur(0.7rem);
  overflow: hidden;
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.prev__page--CTA {
  width: 100%;
  display: flex;
  /* background-color: aquamarine; */
  justify-content: flex-end;
}

.prev__CTA {
  width: 135px;
  height: 45px;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  border: 0.5px solid #e5e5e5;
  border-radius: 20rem;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #181a49;
  display: flex;
  align-items: center;
}
