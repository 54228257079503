.join__community-container {
  background-color: red;
  position: relative;
  align-items: center;
  flex-direction: column;
  border-radius: 1.2rem;
  border: 0.5px solid #b5c9ff;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.28) 0%, #fff 100%);
  padding: 1.2rem;
  margin-bottom: 5rem;
}

.close__join-com--dialog {
  display: flex;
  justify-content: flex-end;
}

.close__com-dialog--text {
  color: #c00000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 121.8%; /* 14.616px */
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.join__community-and-close {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 1.3rem; */
}

.join__community-text {
  color: var(--Cykea-Main, #181a49);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.join__community-text--two {
  color: var(--Cykea-sub-text, #6b6790);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* width: 12rem; */
  text-align: center;
}

.join__community-bg {
  height: 17rem;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  background-position: center;
  text-align: center;
}

.join__comm-link {
  position: absolute;
  bottom: 2rem;
}

.join__community-CTA {
  border-radius: 4rem;
  border: 0.75px solid var(--Linner_3, #eae2ff);
  background: linear-gradient(267deg, #e6dcff 27.79%, #9accfb 90.72%);
  box-shadow: 0px 2px 15px 0px rgba(115, 171, 255, 0.16);
  color: #181a49;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.8rem 1.8rem;
}
