.ethical__section-one--container {
  padding: 5rem 0rem;
  background: #f9f7fd;
}

.ethical__section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.6rem;
}

.ethical__section-one-boxes {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 5rem;
}

.ethical__section-one-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(263.93deg, #f3fbff 34.07%, #f3fbff 146.65%);
  border-radius: 4rem;
  min-width: 34rem;
  max-width: 40rem;
  padding: 5rem 2.6rem;
  border: 1px solid #e2f4ff;
}

.ethical__box-text {
  font-family: Red Hat Display;
  font-size: 17px;
  font-weight: 400;
  line-height: 29.58px;
  text-align: center;
}

.ethical__box-two {
  background: linear-gradient(237.45deg, #fff7db 19.08%, #fff8ec 86.96%);
}
.ethical__box-image {
  width: 14.2rem;
  height: auto;
  margin-bottom: 4rem;
}

.ethical__section-one-big--box {
  min-width: 34rem;
  height: 32.8rem;
  background-image: url(../../../assets/images/section-two-bg-img-mobile.png);
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  padding: 5.4rem 0 3.5rem 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4rem;
  border-radius: 3rem;
}

.ethical__big-box--text {
  font-family: Red Hat Display;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 26.46px;
  text-align: left;
  color: #ffffff;
  width: 22.4rem;
  /* text-decoration: underline; */
}

.contact__info-span {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact__text {
  font-family: Red Hat Display;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 18.58px;
  text-align: left;
  color: #ffffff;
}

.contact__us-email {
  font-family: Red Hat Display;
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 23.89px;
  text-align: left;
  color: #f8a1ff;
  text-decoration: underline;
}

.ethical__section-two--CTA {
  width: 229px;
  height: 60px;
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 800;
  line-height: 23.81px;
  background: #ffffff;
  color: black;
  border-radius: 4rem;
}

@media screen and (min-width: 26rem) {
  .ethical__section-one-big--box {
    width: 40rem;
    /* flex: 1; */
  }
}
@media screen and (min-width: 48rem) {
  .ethical__section-one-boxes {
    flex-direction: row;
  }

  .ethical__section-one-box {
    max-width: none;
  }

  .ethical__section-one-big--box {
    background-image: url(../../../assets/images/section-two-bg-img.png);
    width: 100%;
  }
}
@media screen and (min-width: 62.5rem) {
  .ethical__section-one--container {
    padding: 15rem 0;
  }

  .ethical__section-one-box {
    max-width: 60rem;
    padding: 9.6rem 2.8rem;
  }

  .ethical__box-image {
    width: 16.5rem;
    margin-bottom: 8rem;
  }

  .ethical__box-text {
    font-family: Red Hat Display;
    font-size: 28px;
    font-weight: 400;
    line-height: 37.04px;
    text-align: center;
  }
}

@media screen and (min-width: 90rem) {
  .ethical__section-one-boxes {
    margin-bottom: 11rem;
  }

  .ethical__section-one-big--box {
    width: 1241px;
    height: 567px;
    padding: 10rem 0 10rem 6.5rem;
  }

  .ethical__big-box--text {
    font-size: 36px;
    font-weight: 500;
    line-height: 47.63px;
    width: 40rem;
    /* margin-bottom: 5rem; */
  }

  .contact__info-span {
    gap: 3rem;
  }

  .contact__text {
    font-size: 3rem;
  }

  .contact__us-email {
    font-weight: 800;
    font-size: 3.6rem;
  }

  .ethical__section-two--CTA {
    width: 229px;
    height: 70px;
    font-size: 2rem;
    font-weight: 800;
    line-height: 26.46px;
  }
}
