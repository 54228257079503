.section__four-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem 13rem 2rem;
}

.sectionFour__text-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section__four-img {
  flex-grow: 1;
  width: 80%;
  height: auto;
  margin-bottom: 7rem;
}

.sectionFour__tag-text {
  font-weight: 800;
  font-size: 12px;
  line-height: 150%;
  color: #ec91f4;
  text-align: center;
  margin-bottom: 1.2rem;
}

.section__four-main--text {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 142.27%;
  text-align: center;
  color: #181a49;
  width: 241px;
  margin-bottom: 1.5rem;
}

.section__four-sub--text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #807d9e;
  margin-bottom: 6rem;
}

.sectionFour__CTA {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 173.5%;
  color: #181a49;
  background: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );
  border-radius: 75px;
  padding: 1.6rem 3.7rem;
}

.tab__CTA {
  display: none;
}

.mobile__CTA {
  display: block;
}
@media screen and (min-width: 37.56rem) {
  .section__four-sub--text {
    width: 31rem;
  }

  /* .mobile__CTA {
    display: none;
  } */
}

@media screen and (min-width: 48rem) {
  .section__four-container {
    flex-direction: row;
    padding: 6rem 6rem;
  }

  .section__four-img {
    width: 46%;
    margin-bottom: 0rem;
  }

  .sectionFour__text-container {
    align-items: flex-start;
    /* margin-right: 7rem; */
  }

  .section__four-main--text {
    text-align: left;
    margin-bottom: 1.5rem;
  }

  .section__four-sub--text {
    text-align: left;
    margin-bottom: 2rem;
    /* max-width: 31rem; */
  }

  .tab__CTA {
    display: block;
  }

  .mobile__CTA {
    display: none;
  }
}
@media screen and (min-width: 62rem) {
  .section__four-container {
    padding: 7rem 5rem;
  }
  /* .section__four-img {
            flex-grow: 1;
            width: 80%;
            height: auto;
            margin-bottom: 7rem;
          } */

  .sectionFour__tag-text {
    font-style: normal;
    font-weight: 800;
    font-size: 1.6rem;
    line-height: 150%;
    margin-bottom: 2rem;
  }

  .section__four-main--text {
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 142.27%;
    margin-bottom: 2.9rem;
    width: 36.1rem;
  }

  .section__four-sub--text {
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 160%;
    margin-bottom: 3.6rem;
    width: 42.5rem;
  }

  .sectionFour__CTA {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #181a49;
    padding: 2.2rem 3.3rem;
    border-radius: 6.6rem;
  }
}

@media screen and (min-width: 75rem) {
  .section__four-container {
    padding: 7rem 10rem;
  }
  /* .section__four-img {
              flex-grow: 1;
              width: 80%;
              height: auto;
              margin-bottom: 7rem;
            } */

  .sectionFour__tag-text {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 3rem;
  }

  .section__four-main--text {
    font-style: normal;
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 142.27%;
    margin-bottom: 3.2rem;
    width: 36.1rem;
  }

  .section__four-sub--text {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
    margin-bottom: 4rem;
    width: 46.5rem;
  }

  .sectionFour__CTA {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #181a49;
    padding: 2.2rem 3.3rem;
    border-radius: 6.6rem;
  }
}

@media screen and (min-width: 90rem) {
  .section__four-container {
    padding: 10rem 12rem;
  }
}
@media screen and (min-width: 125rem) /*2000 / 16*/ {
  .section__four-container {
    padding: 10rem 24rem;
  }

  .sectionFour__tag-text {
    font-style: normal;
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 150%;
    margin-bottom: 3rem;
  }

  .section__four-main--text {
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 142.27%;
    width: 45.1rem !important;
    margin-bottom: 3.2rem;
  }

  .section__four-sub--text {
    font-style: normal;
    font-weight: 400;
    font-size: 3.5rem;
    line-height: 131.77%;
    width: 62.3rem;
    margin-bottom: 7rem;
  }

  .sectionFour__CTA {
    padding: 3.9rem 9rem;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
  }
}
