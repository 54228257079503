.series__list--container {
  background-color: #fdfdfd;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  width: 100%;
  padding: 9rem 4rem 2rem;
}

.series__list--content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.seriesList__heading {
  font-weight: 400;
  font-size: 2rem;
  color: #4a5567;
  margin-bottom: 1.5rem;
}

.mobile-series {
  display: none;
}

@media screen and (max-width: 48rem) /*768/16*/ {
  .series__list--content {
    justify-content: center;
  }

  .series__list--container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    width: 100%;
    padding: 11rem 2rem 9rem;
  }

  .mobile-series {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
