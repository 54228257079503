.sectionOne__container {
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2.5rem 7rem 2.5rem;
}

.background__div {
  /* position: relative; */
  width: 89%;
  background-color: red;
  min-width: 30.5rem;
  height: 20.3rem;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
}

.section__one-box {
  width: 100%;
  background-color: #ffffff;
  border-radius: 6.3rem;
}

.bg__border-box {
  /* border: 1.5px solid; */

  background-image: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );

  padding: 1px;
  border-radius: 6.3rem 6.3rem 0 0;
}

.section__one-box-bg {
  background: linear-gradient(
    266.17deg,
    #eae2ff 35.33%,
    rgba(187, 221, 252, 0.28) 121.98%
  );
  height: fit-content;
  border-radius: 6.3rem 6.3rem 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.box__one-badge--icon {
  width: 152px;
  height: 141px;
}

.box__one-cer--icon {
  width: 202px;
  height: 141px;
}

.s2__box-one {
  margin-bottom: 3.5rem;
}

.section__one-box--two {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-width: 340px;
  background-color: purple;
  padding: 2rem;
}

.text__border-box {
  background-image: linear-gradient(
    266.17deg,
    #e6dcff 35.33%,
    rgba(154, 204, 251, 0.72) 121.98%
  );

  padding: 1px;
  border-radius: 0 0 6.3rem 6.3rem;
}
.text__container {
  /* background-color: cyan; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 2rem 0rem 2rem 2.5rem;
  border-radius: 0 0 6.3rem 6.3rem;
}

.box__one-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 131.77%;
  color: #181a49;
  margin-bottom: 1rem;
}

.second__heading {
  /* margin-top: 2.5rem; */
}

.box__one-sub--text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  max-width: 80%;
  color: #807d9e;
}

.space__cover-line--span {
  display: none;
}

@media screen and (min-width: 37.56rem) {
  .section__one-box-bg {
    padding-right: 4rem;
  }

  .text__container {
    padding: 2rem 0rem 2rem 3.5rem;
  }
}

@media screen and (min-width: 48rem) {
  .sectionOne__container {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 3rem 3rem 3rem;
  }
  .s2__box-one {
    margin-right: 1rem;
  }
  .s2__box-two {
    margin-left: 1rem;
  }

  .box__one-sub--text {
    max-width: 28rem;
  }
}
@media screen and (min-width: 62rem) {
  .sectionOne__container {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 5rem 5rem 5rem;
  }

  .section__one-box {
    border-radius: 7.5rem;
  }

  .s2__box-one {
    margin-right: 1rem;
  }
  .s2__box-two {
    margin-left: 1rem;
  }

  .box__one-heading {
    font-size: 2.5rem;
  }

  .bg__border-box {
    border-radius: 7.5rem 7.5rem 0 0;
  }

  .section__one-box-bg {
    border-radius: 7.5rem 7.5rem 0 0;
  }

  .text__border-box {
    background-image: linear-gradient(
      266.17deg,
      #e6dcff 35.33%,
      rgba(154, 204, 251, 0.72) 121.98%
    );

    padding: 1px;
    border-radius: 0 0 9rem 9rem;
  }

  .text__container {
    padding: 2.5rem 0rem 2.5rem 4.5rem;
    z-index: 1;
    position: relative;
    border-radius: 0 0 9rem 9rem;
  }

  .box__one-sub--text {
    font-size: 1.7rem;
    max-width: 34rem;
  }

  .box__one-badge--icon {
    width: 259px;
    height: auto;
    margin-bottom: 0rem;
  }

  .box__one-cer--icon {
    width: 299px;
    height: auto;
  }
}

@media screen and (min-width: 75rem) {
}

@media screen and (min-width: 90rem) {
  .sectionOne__container {
    padding: 0rem 11.5rem 11rem 11rem;
  }

  .background__div {
    height: 28.5rem;
  }

  .section__one-box {
    border-radius: 8.4rem;
  }

  .s2__box-one {
    margin-right: 3.5rem;
  }
  .s2__box-two {
    margin-left: 3.5rem;
  }

  .box__one-heading {
    font-style: normal;
    font-size: 3rem;
    line-height: 131.77%;
    margin-bottom: 2rem;
  }

  .box__one-sub--text {
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 160%;
    max-width: 48rem;
  }
}
@media screen and (min-width: 100rem) /*2000 / 16*/ {
  .sectionOne__container {
    padding: 0 10rem 17.5rem 10rem;
  }
}
@media screen and (min-width: 125rem) /*2000 / 16*/ {
  .sectionOne__container {
    padding: 8rem 15.5rem 17.5rem 16.5rem;
  }

  .background__div {
    height: 45.3rem;
  }

  .section__one-box-bg {
    padding-right: 10rem;
  }

  .s2__box-one {
    margin-right: 4rem;
  }
  .s2__box-two {
    margin-left: 4rem;
  }

  .section__one-box {
    border-radius: 15.4rem;
  }
  .text__container {
    padding: 2.9rem 3rem 5rem 5.5rem;
  }
  .box__one-heading {
    font-weight: 700;
    font-size: 45px;
    line-height: 131.77%;
    margin-bottom: 2.5rem;
  }

  .box__one-sub--text {
    font-weight: 400;
    font-size: 36px;
    line-height: 168.5%;
    max-width: 83rem;
  }

  .space__cover-line--span {
    display: flex;
    /* background-color: red; */
    justify-content: center;
    margin-top: 7rem;
    margin-bottom: 9rem;
  }

  .space__cover-line {
    width: 77.1rem;
    height: 1px;
    background: #f8b4ff;
  }

  .box__one-cer--icon {
    width: 362px;
    height: auto;
  }

  .box__one-badge--icon {
    width: 312px;
    height: auto;
  }
}
